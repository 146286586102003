export {init};

function initLstJobDataTable() {
    var currentLang = getCookie("language");
    //console.log(currentLang);

    var language = {};

    if (currentLang === 'fr_BE') {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json"
        }
    } else if (currentLang === 'nl_BE') {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json"
        }
    } else {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json"
        }
    }
    
    var dtOptions = {
        language: language
    };

    $('#list_compagnies').DataTable(dtOptions);

}

function init() {
    initLstJobDataTable();
        //*********/
        // COMPANY*/
        //*********/
        $('a[data-toggle=modal]').click(function () {
            var data_countryjob= $(this).data('country') || '';
            var data_company_name = $(this).data('companyname') || '';
            var data_adress = $(this).data('adress') || '';
            var data_adressadd = $(this).data('adressadd') || '';
            var data_building = $(this).data('building') || '';
            var data_postalcode = $(this).data('postalcode') || '';
            var data_city = $(this).data('city') || '';
            var data_province = $(this).data('province') || '';
            var data_country = $(this).data('country') || '';
            var data_phone= $(this).data('phone') || '';
            var data_urlcompanyfr = $(this).data('urlcompanyfr') || '';
            var data_urlcompanynl = $(this).data('urlcompanynl') || '';
            var data_urlcompanyen = $(this).data('urlcompanyen') ||'';
            var data_urlcompanyjobfr = $(this).data('urlcompanyjobfr') ||'';
            var data_urlcompanyjobnl = $(this).data('urlcompanyjobnl') || '';
            var data_urlcompanyjoben = $(this).data('urlcompanyjoben') || '';
            var data_companymail = $(this).data('companymail') || '';
            var data_companbyvat = $(this).data('companbyvat') || '';
            var data_recruitingmanagername1 = $(this).data('recruitingmanagername1') || '';
            var data_recruitingmanagermail1 = $(this).data('recruitingmanagermail1') || '';
            var data_recruitingmanagerphone1 = $(this).data('recruitingmanagerphone1') || '';
            var data_recruitingmanagername2 = $(this).data('recruitingmanagername2') || '';
            var data_recruitingmanagermail2 = $(this).data('recruitingmanagermail2') || '';
            var data_recruitingmanagerphone2 = $(this).data('recruitingmanagerphone2') || '';
            var data_groupname = $(this).data('groupname') || '';
            var data_companytype = $(this).data('companytype') || '';
            var data_descriptionfr = $(this).data('descriptionfr') || '';
            var data_descriptionnl = $(this).data('descriptionnl') || '';
            var data_descriptionen = $(this).data('descriptionen') || '';
            var data_lang= $(this).data('lang') || 'fr';
            $('#confirm-delete-company').on('show.bs.modal', function(e) {
                ////console.log( data-company-name);
                ////console.log(active);
                //debugger
                $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
                $('.debug-url').html('Are you sure you want to delete: <strong>' + data_company_name + '</strong>');
            });

            $('#view-details-company').on('show.bs.modal', function(e) {
                //console.log(data_company_name);
                //debugger
                $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));

                $('.debug-url').html('Are you sure you want to delete: <strong>' + data_company_name + '</strong>');

                                    /*+ '<br>French description: ' + data_descriptionfr
                                    + '<br>Dutch Description: ' + data_descriptionnl
                                    + '<br>English Description: ' + data_descriptionen)*/
                    $('.detailCompany').html('<hr/>'
                                    +'<p><strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Détails de la société' : data_lang === 'nl' ? 'Bedrijfsgegevens' : 'Society details') +' :</span></u></strong></p>'
                                    +'<table border="1" cellpadding="1" cellspacing="1" class="w-100">'
                                    +'<tbody>'
                                    +'<tr>'
                                    +'<td colspan="4"><strong>'+ (data_lang === 'fr' ? 'Nom' : data_lang === 'nl' ? 'Naam' : 'Name') +':</strong>' + data_company_name + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td colspan="2" rowspan="1"><strong>'+ (data_lang === 'fr' ? 'Pays' : data_lang === 'nl' ? 'Land' : 'Country') +':</strong>' + data_countryjob + '</td>'
                                    +'<td colspan="2" rowspan="1"><strong>'+ (data_lang === 'fr' ? 'Province' : data_lang === 'nl' ? 'Provincie' : 'Province') +':</strong>' + data_province + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td><strong>'+ (data_lang === 'fr' ? 'Ville' : data_lang === 'nl' ? 'Stad' : 'City') +':</strong> ' + data_city + '</td>'
                                    +'<td><strong>'+ (data_lang === 'fr' ? 'Code postal' : data_lang === 'nl' ? 'Postcode' : 'Postal&nbsp;code') +':</strong> ' + data_postalcode + '</td>'
                                    +'<td colspan="2" rowspan="1"><strong>'+ (data_lang === 'fr' ? 'Immeuble' : data_lang === 'nl' ? 'Gebouw' : 'Building') +':</strong> ' +data_building + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td colspan="4"><strong>'+ (data_lang === 'fr' ? 'Adresse' : data_lang === 'nl' ? 'Adres' : 'Address') +' 1:</strong>' + data_adress + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td colspan="4"><strong>'+ (data_lang === 'fr' ? 'Adresse' : data_lang === 'nl' ? 'Adres' : 'Address') +' 2:</strong>' + data_adressadd + '</td>'
                                    +'</tr>'
                                    +'</tbody>'
                                    +'</table>'
                                    +'<p><br />'
                                    +'<strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Détails Web' : data_lang === 'nl' ? 'Webdetails' : 'Web details') +' :</span></u></strong></p>'
                                    +'<ul>'
                                    +'<li>'+ (data_lang === 'fr' ? 'URL de la société' : data_lang === 'nl' ? 'URL bedrijf' : 'Url company') +' fr: <a href="http://' + data_urlcompanyfr + '">' + data_urlcompanyfr + '</a></li>'
                                    +'<li>'+ (data_lang === 'fr' ? 'URL de la société emploi' : data_lang === 'nl' ? 'URL van het werkgelegenheidsbedrijf' : 'Url company job') +' fr: <a href="http://' + data_urlcompanyjobfr + '">' + data_urlcompanyjobfr + '</a></li>'
                                    +'<li>'+ (data_lang === 'fr' ? 'URL de la société' : data_lang === 'nl' ? 'URL bedrijf' : 'Url company') +' nl: <a href="http://' + data_urlcompanynl + '">' + data_urlcompanynl + '</a></li>'
                                    +'<li>'+ (data_lang === 'fr' ? 'URL de la société emploi' : data_lang === 'nl' ? 'URL van het werkgelegenheidsbedrijf' : 'Url company job') +' nl: <a href="http://' + data_urlcompanyjobnl + '">' + data_urlcompanyjobnl + '</a></li>'
                                    +'<li>'+ (data_lang === 'fr' ? 'URL de la société' : data_lang === 'nl' ? 'URL bedrijf' : 'Url company') +' en: <a href="http://' + data_urlcompanyen + '">' + data_urlcompanyen + '</a></li>'
                                    +'<li>'+ (data_lang === 'fr' ? 'URL de la société emploi' : data_lang === 'nl' ? 'URL van het werkgelegenheidsbedrijf' : 'Url company job') +' en: <a href="http://' + data_urlcompanyjoben + '">' + data_urlcompanyjoben + '</a><br />'
                                    +' </li>'
                                    +'</ul>'
                                    +'<p><strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Contact' : data_lang === 'nl' ? 'Contact' : 'Contact') +' :</span></u></strong></p>'
                                    +'<table border="1" cellpadding="1" cellspacing="1" class="w-100">'
                                    +'<tbody>'
                                    +'<tr>'
                                    +'<td colspan="3"><strong>'+ (data_lang === 'fr' ? 'Téléphone' : data_lang === 'nl' ? 'Telefoon' : 'Phone') +' :</strong>' + data_phone + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td colspan="3"><strong>'+ (data_lang === 'fr' ? 'E-mail de l\'entreprise' : data_lang === 'nl' ? 'Bedrijf e-mailadres' : 'Company&nbsp;email') +' :</strong>' + data_companymail + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td colspan="1" rowspan="4"><strong>'+ (data_lang === 'fr' ? 'Responsable du recrutement' : data_lang === 'nl' ? 'Werving & nbsp;manager' : 'Recruiting&nbsp;manager') +' :</strong></td>'
                                    +'<td colspan="2" rowspan="1"><strong>'+ (data_lang === 'fr' ? 'Nom' : data_lang === 'nl' ? 'Naam' : 'Name') +' 1:</strong> ' + data_recruitingmanagername1 + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td><strong>'+ (data_lang === 'fr' ? 'Courriel' : data_lang === 'nl' ? 'E-mail' : 'E-mail') +' 1: </strong>' + data_recruitingmanagermail1 + '</td>'
                                    +'<td><strong>'+ (data_lang === 'fr' ? 'Téléphone' : data_lang === 'nl' ? 'Telefoon' : 'Phone') +' 1:</strong>' + data_recruitingmanagerphone1 + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td colspan="2" rowspan="1"><strong>'+ (data_lang === 'fr' ? 'Nom' : data_lang === 'nl' ? 'Naam' : 'Name') +' 2:</strong>' + data_recruitingmanagername2 + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td><strong>'+ (data_lang === 'fr' ? 'Courriel' : data_lang === 'nl' ? 'E-mail' : 'E-mail') +' 2:</strong>' + data_recruitingmanagermail2 + '</td>'
                                    +'<td><strong>'+ (data_lang === 'fr' ? 'Téléphone' : data_lang === 'nl' ? 'Telefoon' : 'Phone') +' 2:</strong>' + data_recruitingmanagerphone2 + '</td>'
                                    +'</tr>'
                                    +'</tbody>'
                                    +'</table>'
                                    +'<p><br />'
                                    +'<strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Informations' : data_lang === 'nl' ? 'Informatie' : 'Informations') +' :</span></u></strong></p>'
                                    +'<table border="1" cellpadding="1" cellspacing="1" class="w-100">'
                                    +'<tbody>'
                                    +'<tr>'
                                    +'<td><strong>'+ (data_lang === 'fr' ? 'Nom de groupe' : data_lang === 'nl' ? 'Groepsnaam' : 'Group name') +' :</strong>' + data_groupname + '</td>'
                                    +'<td><strong>'+ (data_lang === 'fr' ? 'Type de compagnie' : data_lang === 'nl' ? 'Bedrijfstype' : 'Company type') +' :</strong>' + data_companytype + '</td>'
                                    +'</tr>'
                                    +'<tr>'
                                    +'<td colspan="2"><strong>'+ (data_lang === 'fr' ? 'TVA sur les sociétés' : data_lang === 'nl' ? 'BTW bedrijf' : 'Company VAT') +' :</strong>' + data_companbyvat + '</td>'
                                    +'</tr>'
                                    +'</tbody>'
                                    +'</table>')
            });
    });


}