export {init};

var Upload = function (file, progressBarId) {
    this.file = file;
    this.progressBarId = progressBarId;
};

Upload.prototype.getName = function() {
    return this.file.name;
};

Upload.prototype.getDescriptionJobIdRow = function() {
    return this.descriptionJobIdRow;
};

Upload.prototype.doUpload = function () {
    var that = this;
    var formData = new FormData();
    formData.append("importFile", this.file, this.getName());
    formData.append('id_company', $('#company').val());

    $.ajax({
        type: "POST",
        url: "/imp-exp/import",
        data: formData,
        xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
                myXhr.upload.addEventListener('progress', that.progressHandling.bind(null, that.progressBarId), false);
            }
            return myXhr;
        },

        success: function (data) {
            /**
             * The Ajax answer has two different structures
             * If the error is "deep". it looks like
             * data = [
             *     'error': true,
             *     'error_code' : '...',
             *      ...
             * ]
             *
             * The second structure is the answer from the XLSX parser (The Controller can also add some other error, after it):
             * data = [
             *     'errors': [],
             *     'metaData': [
             * 
             *          'currentXLSXVersion' : '...',
             *          'currentLang': '...',
             *          'idLang': 1
             *      ],
             *     'rowsToImport': [...],    // Only present in Debug Mode.
             * ]
             */
            $('#progress-import-upload-container').addClass('hidden');
            try {
                var parsedData = JSON.parse(data);
            } catch (error) {
                //console.debug(data);
                console.error('Ajax answer malformed. Could not parse');
                return;
            }

            if (typeof parsedData.error !== 'undefined' && parsedData.error) {
                console.error('a problem occured: ', parsedData.error_code);
                return
            }

            if (typeof parsedData.errors !== 'undefined') {
                processXLSXImporterErrors(parsedData.errors, parsedData.metaData);
            }
        },

        error: function (error) {
            // handle error
        },
        cache: false,
        processData: false,
        contentType: false,
        timeout: 60000,
        async: true,
    });
};

Upload.prototype.progressHandling = function (progressBarId, event) {
    var percent = 0;
    var position = event.loaded || event.position;
    var total = event.total;
    if (event.lengthComputable) {
        percent = Math.ceil(position / total * 100);
    }

    $('#' + progressBarId).attr('aria-valuenow', percent);
    $('#' + progressBarId).css({'width': percent + '%'});
    $('#' + progressBarId).html(percent + '%');
};

function replacePlaceHolders(text, placeholders) {
    text = text.replace(/%\w+%/g, function(all) {
        return placeholders[all] || all;
    });
    return text;
}

function addOutputMessage(cssClass, placeHolders) {
    var elem = $('#import-message-prototypes ' + cssClass).clone().appendTo("#import-error-container");
    var newText = replacePlaceHolders(elem.html(), placeHolders);
    elem.html(newText);
}

function processXLSXImporterErrors(errors, metaData) {
    if (errors.length == 0) {
        // No errors, Success
        var placeHolders = {
            '%NBR_IMPORTED_ROWS%': metaData['rowsImported']
        }
        addOutputMessage('.msg-success', placeHolders)
        return;
    }

    let warningCount = 0;
    let fatalCount = 0;
    // Count error type
    for (var i = 0; i < errors.length; i++) {
        let error = errors[i];
        if (error.type == "WARNING") {
            warningCount++;
        }
        if (error.type == "FATAL") {
            fatalCount++;
        }
    }

    if (fatalCount == 0 && warningCount) {
        // TODO
    }

    for (var i = 0; i < errors.length; i++) {
        let error = errors[i];
        switch(error.code) {
            case "BAD_MAIL_ADDRESS":
                var placeHolders = {
                    '%CELL_VALUE%': error.details.cellValue,
                    '%COL_LABEL%': error.details.colLabel,
                    '%COORDS%': error.details.coords,
                }
                addOutputMessage('.msg-bad-mail-address', placeHolders);
                break;
            case "BAD_URL":
                var placeHolders = {
                    '%CELL_VALUE%': error.details.cellValue,
                    '%COL_LABEL%': error.details.colLabel,
                    '%COORDS%': error.details.coords,
                }
                addOutputMessage('.msg-bad-url', placeHolders);
                break;
            case "COULD_NOT_PARSE":
                placeHolders = {};
                addOutputMessage('.msg-could-not-parse', placeHolders);
                break;
            case "META_DATA_SHEET_IS_MISSING":
                placeHolders = {};
                addOutputMessage('.msg-meta-data-sheet-is-missing', placeHolders);
                break;
            case "VERSION_AND_LANG_CANNOT_BE_FOUND":
                placeHolders = {};
                addOutputMessage('.msg-version-and-lang-cannot-be-found', placeHolders);
                break;
            case "SHEET_NBR_NOT_EQ":
                placeHolders = {};
                addOutputMessage('.msg-sheet-nbr-not-eq', placeHolders);
                break;
            case "SHEET_MISSING":
                placeHolders = {
                    '%EXPECTED_SHEET%': error.details.expectedSheet
                };
                addOutputMessage('.msg-sheet-nbr-not-eq', placeHolders);
                break;
            case "WRONG_COL_NAME":
                placeHolders = {
                    '%CURRENT_SHEET%': error.details.currentSheet,
                    '%EXPECTED_COL_NAME%': error.details.expectedColName,
                    '%GOT_COL_NAME%': error.details.gotColName,
                };
                addOutputMessage('.msg-wrong-col-name', placeHolders);
                break;
            case "REQUIRED_FIELD_NOT_SET":
                placeHolders = {
                    '%COORDS%': error.details.coords,
                    '%COL_LABEL%': error.details.colLabel,
                };
                addOutputMessage('.msg-required-field-not-set', placeHolders);
                break;
            case "LABEL_DOES_NOT_EXISTS":
                placeHolders = {
                    '%CELL_VALUE%': error.details.cellValue,
                    '%COL_LABEL%': error.details.colLabel,
                    '%COORDS%': error.details.coords,
                };
                addOutputMessage('.msg-label-does-not-exists', placeHolders);
                break;
            case "BAD_LABEL":
                break;
            case "BAD_BOOLEAN_STR_VALUE":
                break;
        }
    }
}


function onSubmitImportJobOffersSubmit(event) {
    event.preventDefault();
    $('#import-error-container').html('');

    if ($('#file-to-import')[0].files && $('#file-to-import')[0].files.length) {
        $('#progress-import-upload-container').removeClass('hidden');
        var file = $('#file-to-import')[0].files[0];
        var currentProgressBarId = 'progress-import-upload';
        var upload = new Upload(file, currentProgressBarId);
        upload.doUpload();
    } else {
        $('#import-file-missing-alert').removeClass('hidden');
    }
}


function onSubmitExportJobOffersSubmit(event) {
    event.preventDefault();

    var formData = new FormData();

    // Get id list of selected compagnies
    var checkedBox = $('.container-comp input[type=checkbox]:checked');
    if (checkedBox.length == 0) {
        $('#export-no-company-selected-alert').removeClass('hidden');
        return;
    }
    $('#export-no-company-selected-alert').addClass('hidden');

    for (var i = 0; i < checkedBox.length; i++) {
        var idCompany = $(checkedBox[i]).val();
        formData.append('lstCompany[]', idCompany);
    }
    $('.alert').addClass('hidden');

    $.ajax({
        type: "POST",
        url: "/imp-exp/export",
        data: formData,
        success: function (data) {
            try {
                var parsedData = JSON.parse(data);
            } catch (error) {
                //console.debug(data);
                console.error('Ajax answer malformed. Could not parse');
                return;
            }

            if (typeof parsedData.error !== 'undefined' && parsedData.error) {
                switch (parsedData.error_code) {
                    case "DATA_NOT_FOUND":
                        $('#export-no-data-found-alert').removeClass('hidden');
                        break;
                    case "LST_COMPANY_NOT_PRESENT":
                    case "LST_COMPANY_IS_NOT_AN_ARRAY":
                    case "LST_COMPANY_MUST_HAVE_AT_LEAST_ONE_ELEMENT":
                    case "LST_COMPANY_EVERY_ELEMENT_MUST_BE_INT":
                    case "CANNOT_CREATE_CSV_FILE":
                    default:
                        $('#export-default-error-alert').removeClass('hidden');
                        console.error('a problem occured: ', parsedData.error_code);
                        break;
                }
                return
            }

            window.location.replace('/imp-exp/download-export/' + parsedData.data);
        },

        error: function (error) {
            // handle error
        },
        cache: false,
        processData: false,
        contentType: false,
        timeout: 60000,
        async: true,
    });
}

/**
 * Export company list. Event Handler and pagination
 */
function pagifyCompanyList() {
    if (window.matchMedia('(max-width: 768px)').matches) {
        $(".container-comp").pagify(3, ".container-choice-company");
    }

    if (window.matchMedia('(min-width: 768px)').matches) {
        $(".container-comp").pagify(6, ".container-choice-company");
    }

    if (window.matchMedia('(min-width: 1200px)').matches) {
        $(".container-comp").pagify(9, ".container-choice-company");
    }
}


function updateCompanyCheckboxs() {
    var checkboxes = $('.container-choice-company input[type="checkbox"]');
    var countCheckedCheckboxes = checkboxes.filter(':checked').length;

    $('#nbrcompsel').text(countCheckedCheckboxes);
    $('#img-company-off-left').addClass('hiden')
    $('#img-company-on-left').removeClass('hiden');
    $('#img-company-off-result').addClass('hiden');
    $('#img-company-on-result').removeClass('hiden');
    $('.filter-result-company-yes').removeClass('hiden');
    $('.filter-result-company-no').addClass('hiden');
    //$('.filter-result-company-yes').text(countCheckedCheckboxes);
    $('.count-comp').text(countCheckedCheckboxes);
    $('.no-data-company').addClass('hiden');
    $('.yes-data-company').removeClass('hiden');
    $('.company_selected-result').text(countCheckedCheckboxes);
    $('.filter-result-company-no').addClass('hiden');
    $('.filter-result-company-yes').removeClass('hiden');
    $('#export-no-company-selected-alert').addClass('hidden');
}

function downloadFile(jobType, fileType) {
    return fileProcess(jobType, fileType);
}

function fileProcess(jobType, fileType) {
    
    // //console.log('downloadCSE_CSVFile click works !!', ids_compagnies, formType);

    var formData = new FormData();

    formData.append('jobType', jobType);
    formData.append('fileType', fileType);

    $.ajax({
        type: "POST",
        url: "/imp-exp/export-jobs",
        data: formData,
        success: function (data) {
            // //console.log(data);
            try {
                var parsedData = JSON.parse(data);
                // //console.log(parsedData);
            } catch (error) {
                //console.debug(data);
                console.error('Ajax answer malformed. Could not parse');
                return;
            }

            if (typeof parsedData.error !== 'undefined' && parsedData.error) {
                switch (parsedData.error_code) {
                    case "DATA_NOT_FOUND":
                        $('#export-no-data-found-alert').removeClass('hidden');
                        break;
                    case "LST_COMPANY_NOT_PRESENT":
                    case "LST_COMPANY_IS_NOT_AN_ARRAY":
                    case "LST_COMPANY_MUST_HAVE_AT_LEAST_ONE_ELEMENT":
                    case "LST_COMPANY_EVERY_ELEMENT_MUST_BE_INT":
                    case "CANNOT_CREATE_CSV_FILE":
                    default:
                        $('#export-default-error-alert').removeClass('hidden');
                        console.error('a problem occured: ', parsedData.error_code);
                        break;
                }
                return
            }

            window.location.replace('/imp-exp/download-export/'+ fileType +'/' + parsedData.data);
        },

        error: function (error) {
            // handle error
        },
        cache: false,
        processData: false,
        contentType: false,
        timeout: 60000,
        async: true,
    });
}

function init() {
    $('#importJobOffers').submit(onSubmitImportJobOffersSubmit);
    $('#exportJobOffers').submit(onSubmitExportJobOffersSubmit);

    $('#file-to-import').on('change', function(event) {
        $('#import-file-missing-alert').addClass('hidden');
    });

    $('#downloadJob_csvfile').click(function(event) {
        event.preventDefault();
        var type = $(this).data('type') || 'csv';
        //console.log('downloadJob_csvfile', type);
        downloadFile('job', type);
    });

    $('#downloadJob_xlsxfile').click(function(event) {
        event.preventDefault();
        var type = $(this).data('type') || 'xlsx';
        //console.log('downloadJob_xlsxfile', type);
        downloadFile('job', type);
    });

    $('#downloadInternship_csvfile').click(function(event) {
        event.preventDefault();
        var type = $(this).data('type') || 'csv';
        //console.log('downloadInternship_csvfile', type);
        downloadFile('internship', type);
    });

    $('#downloadInternship_xlsxfile').click(function(event) {
        event.preventDefault();
        var type = $(this).data('type') || 'xlsx';
        //console.log('downloadInternship_xlsxfile', type);
        downloadFile('internship', type);
    });

    /**
     * Company List Pagination
     */

    // Function pagify for pagination
    (function($) {
        var pagify = {
            items: {},
            container: null,
            totalPages: 1,
            perPage: 3,
            currentPage: 0,
            createNavigation: function() {
                this.totalPages = Math.ceil(this.items.length / this.perPage);

                $('.pagination', this.container.parent()).remove();
                var pagination = $('<div class="pagination justify-content-center d-flex flex-wrap"></div>').append(
                    '<a class="nav prev disabled" data-next="false"><i class="fas fa-chevron-left"></i></a>'
                );

                for (var i = 0; i < this.totalPages; i++) {
                    var pageElClass = "page";
                    if (!i)
                        pageElClass = "page current";
                    var pageEl = '<a class="' + pageElClass + '" data-page="' + (
                    i + 1) + '">' + (
                    i + 1) + "</a>";
                    pagination.append(pageEl);
                }
                pagination.append('<a class="nav next" data-next="true"><i class="fas fa-chevron-right"></i></a>');

                this.container.after(pagination);

                var that = this;
                $("body").off("click", ".nav");
                this.navigator = $("body").on("click", ".nav", function() {
                    var el = $(this);
                    that.navigate(el.data("next"));
                });

                $("body").off("click", ".page");
                this.pageNavigator = $("body").on("click", ".page", function() {
                    var el = $(this);
                    that.goToPage(el.data("page"));
                });
            },
            navigate: function(next) {
                // default perPage to 5
                if (isNaN(next) || next === undefined) {
                    next = true;
                }
                $(".pagination .nav").removeClass("disabled");
                if (next) {
                    this.currentPage++;
                    if (this.currentPage > (this.totalPages - 1))
                        this.currentPage = (this.totalPages - 1);
                    if (this.currentPage == (this.totalPages - 1))
                        $(".pagination .nav.next").addClass("disabled");
                    }
                else {
                    this.currentPage--;
                    if (this.currentPage < 0)
                        this.currentPage = 0;
                    if (this.currentPage == 0)
                        $(".pagination .nav.prev").addClass("disabled");
                    }

                this.showItems();
            },
            updateNavigation: function() {

                var pages = $(".pagination .page");
                pages.removeClass("current");
                $('.pagination .page[data-page="' + (
                this.currentPage + 1) + '"]').addClass("current");
            },
            goToPage: function(page) {

                this.currentPage = page - 1;

                $(".pagination .nav").removeClass("disabled");
                if (this.currentPage == (this.totalPages - 1))
                    $(".pagination .nav.next").addClass("disabled");

                if (this.currentPage == 0)
                    $(".pagination .nav.prev").addClass("disabled");
                this.showItems();
            },
            showItems: function() {
                this.items.hide();
                var base = this.perPage * this.currentPage;
                this.items.slice(base, base + this.perPage).show();

                this.updateNavigation();
            },
            init: function(container, items, perPage) {
                this.container = container;
                this.currentPage = 0;
                this.totalPages = 1;
                this.perPage = perPage;
                this.items = items;
                this.createNavigation();
                this.showItems();
            }
        };

        // stuff it all into a jQuery method!
        $.fn.pagify = function(perPage, itemSelector) {
            var el = $(this);
            var items = $(itemSelector, el);

            // default perPage to 5
            if (isNaN(perPage) || perPage === undefined) {
                perPage = 3;
            }

            // don't fire if fewer items than perPage
            if (items.length <= perPage) {
                return true;
            }

            pagify.init(el, items, perPage);
        };
    })(jQuery);

    $(window).resize(pagifyCompanyList);
    pagifyCompanyList();

    $('.container-choice-company').click(function(e){
        e.preventDefault();
        var checkbox = $(this).children('input[type="checkbox"]');
        $(checkbox).prop('checked', !$(checkbox).prop('checked'));

        if($(this).children('input[type="checkbox"]').is(":checked")){
            $(this).children('input[type="checkbox"]').attr('checked','checked');
        }
        else if($(this).is(":not(:checked)")){
            $(this).children('input[type="checkbox"]').removeAttr('checked');
        }

        updateCompanyCheckboxs();
    });
    /*
    var checkboxes = $('.container-choice-company input[type="checkbox"]');
    checkboxes.change(updateCompanyCheckboxs);
    */

    // Handle Select ALL
    $('#select-all-compagnies').click(function(event) {
        if ($(event.currentTarget).prop('checked')) {
            $('input[type=checkbox]').prop('checked', true);
        } else {
            $('input[type=checkbox]').prop('checked', false);
        }
    });
}