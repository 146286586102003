export {init};

function initLstJobDataTable() {
    var currentLang = getCookie("language");
    //console.log(currentLang);

    var language = {};

    if (currentLang === 'fr_BE') {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json"
        }
    } else if (currentLang === 'nl_BE') {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json"
        }
    } else {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json"
        }
    }
    
    var dtOptions = {
        stateSave: true,
        language: language,
        ordering: true,
        order: [[ 4, "desc" ]],
        columnDefs : [{targets: 4, type: "date-uk"}],
    };

    $('#list_ads').DataTable(dtOptions);

}

function downloadFile(type) {
    return fileProcess('job', type);
}

function fileProcess(jobType, fileType) {
    
    // //console.log('downloadCSE_CSVFile click works !!', ids_compagnies, formType);

    var formData = new FormData();

    formData.append('jobType', jobType);
    formData.append('fileType', fileType);

    $.ajax({
        type: "POST",
        url: "/imp-exp/export-jobs",
        data: formData,
        success: function (data) {
            // //console.log(data);
            try {
                var parsedData = JSON.parse(data);
                // //console.log(parsedData);
            } catch (error) {
                console.debug(data);
                console.error('Ajax answer malformed. Could not parse');
                return;
            }

            if (typeof parsedData.error !== 'undefined' && parsedData.error) {
                switch (parsedData.error_code) {
                    case "DATA_NOT_FOUND":
                        $('#export-no-data-found-alert').removeClass('hidden');
                        break;
                    case "LST_COMPANY_NOT_PRESENT":
                    case "LST_COMPANY_IS_NOT_AN_ARRAY":
                    case "LST_COMPANY_MUST_HAVE_AT_LEAST_ONE_ELEMENT":
                    case "LST_COMPANY_EVERY_ELEMENT_MUST_BE_INT":
                    case "CANNOT_CREATE_CSV_FILE":
                    default:
                        $('#export-default-error-alert').removeClass('hidden');
                        console.error('a problem occured: ', parsedData.error_code);
                        break;
                }
                return
            }

            window.location.replace('/imp-exp/download-export/'+ fileType +'/' + parsedData.data);
        },

        error: function (error) {
            // handle error
        },
        cache: false,
        processData: false,
        contentType: false,
        timeout: 60000,
        async: true,
    });
}

function init() {

    initLstJobDataTable();
    //jobTabInitialization();

    // //console.log("Hi there !!");

    $('#downloadJob_csvfile').click(function(event) {
        event.preventDefault();
        var type = $(this).data('type') || 'csv';
        //console.log('downloadJob_csvfile', type);
        downloadFile(type);
    });

    $('#downloadJob_xlsxfile').click(function(event) {
        event.preventDefault();
        var type = $(this).data('type') || 'xlsx';
        //console.log('downloadJob_xlsxfile', type);
        downloadFile(type);
    });
    
    $('.MyModalPreview #tab1frjob').on('click', function(e) {

        $('.MyModalPreview #tab1frjob').removeClass('inactive').addClass('active');
        $('.MyModalPreview #tab2nljob').removeClass('active').addClass('inactive');
        $('.MyModalPreview #tab3enjob').removeClass('active').addClass('inactive');
        $('.MyModalPreview #description-job-fr').show('slow');
        $('.MyModalPreview #description-job-nl').hide('slow');
        $('.MyModalPreview #description-job-en').hide('slow');
    });
    $('.MyModalPreview #tab2nljob').on('click', function(e) {

        $('.MyModalPreview #tab2nljob').removeClass('inactive').addClass('active')
        $('.MyModalPreview #tab1frjob').removeClass('active').addClass('inactive');
        $('.MyModalPreview #tab3enjob').removeClass('active').addClass('inactive');
        $('.MyModalPreview #description-job-fr').hide('slow');
        $('.MyModalPreview #description-job-nl').show('slow');
        $('.MyModalPreview #description-job-en').hide('slow');
    });
    $('.MyModalPreview #tab3enjob').on('click', function(e) {
        $('.MyModalPreview #tab3enjob').removeClass('inactive').addClass('active')
        $('.MyModalPreview #tab1frjob').removeClass('active').addClass('inactive');
        $('.MyModalPreview #tab2nljob').removeClass('active').addClass('inactive');
        $('.MyModalPreview #description-job-fr').hide('slow');
        $('.MyModalPreview #description-job-nl').hide('slow');
        $('.MyModalPreview #description-job-en').show('slow');
    });

	$('.input-daterange input').datepicker({
	    format: 'dd/mm/yyyy',
	    startDate: 'd',
	    language: 'fr'
    });

    $('a[data-toggle=modal]').click(function () {
        var data_companyname= $(this).data('companyname') || '';
        var data_mailcontact= $(this).data('mailcontact') || '';
        var data_namecontact= $(this).data('namecontact') || '';
        var data_telcontact= $(this).data('telcontact') || '';
        var data_country = $(this).data('country') || '';
        var data_region= $(this).data('region') || '';
        var data_city= $(this).data('city') || '';
        var data_titlefunction= $(this).data('titlefunction') || '';
        var data_functiondescription= $(this).data('functiondescription') || '';
        var data_profildescription= $(this).data('profildescription') || '';
        var data_jobdescription= $(this).data('jobdescription') || '';
        var data_descriptionadd= $(this).data('descriptionadd') || '';
        var data_urljob= $(this).data('urljob') || '';
        var data_createdat= $(this).data('createdat') || '';
        var data_idcreatedat= $(this).data('idcreatedat') || '';
        var data_updatedat= $(this).data('updatedat') || '';
        var data_idupdatedat= $(this).data('idupdatedat') || '';
        var data_onlinedatestart= $(this).data('onlinedatestart') || '';
        var data_onlinedateend= $(this).data('onlinedateend') || '';
        var data_jobtype= $(this).data('jobtype') || '';
        var data_contracttype= $(this).data('contracttype') || '';
        var data_functiontype= $(this).data('functiontype') || '';
        var data_idjob = $(this).data('idjob') || '';
        var data_fr_active = $(this).data('fr-active') || '';
        var data_nl_active = $(this).data('nl-active') || '';
        var data_en_active = $(this).data('en-active') || '';
        var data_lang = $(this).data('lang') || 'fr';

        //*********/
        // JOB    */
        //*********/
        $('#confirm-delete-ad').on('show.bs.modal', function(e) {
            $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
            $('.debug-url').html('Are you sure you want to delete: <strong>' + data_idjob + '</strong>');
        });

        $('#view-details-ad').on('show.bs.modal', function(e) {
            $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));

            $('.debug-url').html('Are you sure you want to delete: <strong>' + data_idjob + '</strong>');

            $('.detailAd').html('<p><strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Langage de publication' : data_lang === 'nl' ? 'Taal van publicatie' : 'Language of publication') +' : </span></u></strong> ' + data_fr_active + ' - ' + data_nl_active + ' - '+ data_en_active + '</p>'
                + '<p><strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Détails du poste' : data_lang === 'nl' ? 'Details van de baan' : 'Job details') +' :</span></u></strong></p>'
                + '<table border="1" cellpadding="1" cellspacing="1" class="w-100">'
                + '<tbody>'
                + '<tr>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Société' : data_lang === 'nl' ? 'Samenleving' : 'Society') +' :</strong> ' + data_companyname + '</td>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Type de contrat' : data_lang === 'nl' ? 'Contract type' : 'Contract type') +' :</strong> ' + data_contracttype + '</td>'
                + '</tr>'
                + '<tr>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Type de fonction' : data_lang === 'nl' ? 'Functietype:' : 'Function type') +' :</strong> ' + data_functiontype + '</td>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Type d\'emploi' : data_lang === 'nl' ? 'Soort baan' : 'Job type') +' :</strong> ' + data_jobtype + '</td>'
                + '</tr>'
                + '<tr>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Date de l\'annonce en ligne' : data_lang === 'nl' ? 'Datum van online advertentie' : 'Date of on-line ad') +':</strong> ' +  data_onlinedatestart + '</td>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Date de l\'annonce hors ligne' : data_lang === 'nl' ? 'Datum van offline advertentie' : 'Date of off-line ad') +':</strong> ' + data_onlinedateend + '</td>'
                + '</tr>'
                + '</tbody>'
                + '</table>'
                + '<br>'
                + '<p><strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Contact' : data_lang === 'nl' ? 'Contact' : 'Contact') +' :</span></u></strong></p>'
                + '<table border="1" cellpadding="1" cellspacing="1" class="w-100">'
                + '<tbody>'
                + '<tr>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Nom du contact' : data_lang === 'nl' ? 'Naam van de contact' : 'Name contact') +' :</strong> ' + data_namecontact + '</td>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Téléphone du contact' : data_lang === 'nl' ? 'Contact telefoon' : 'Phone contact') +' :</strong> ' + data_telcontact + '</td>'
                + '</tr>'
                + '<tr>'
                + '<td colspan="2"><strong>'+ (data_lang === 'fr' ? 'Email du contact' : data_lang === 'nl' ? 'E-mail contact' : 'Email contact') +' :</strong> ' + data_mailcontact + '</td>'
                + '</tr>'
                + '</tbody>'
                + '</table>'
                + '<br>'
                + '<p><strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Localisation' : data_lang === 'nl' ? 'Lokalisatie' : 'Localisation') +' :</span></u></strong></p>'
                + '<table border="1" cellpadding="1" cellspacing="1" class="w-100">'
                + '<tbody>'
                + '<tr>'
                + '<td colspan="2"><strong>'+ (data_lang === 'fr' ? 'Pays' : data_lang === 'nl' ? 'Land' : 'Country') +' :</strong> ' + data_country + '</td>'
                + '</tr>'
                + '<tr>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Région' : data_lang === 'nl' ? 'Regio' : 'Region') +' :</strong> ' + data_region + '</td>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Ville' : data_lang === 'nl' ? 'Stad' : 'City') +' :</strong> ' + data_city + '</td>'
                + '</tr>'
                + '</tbody>'
                + '</table>'
                + '<br>'
                + '<p><strong><u><span style="color:#1d71b8">'+ (data_lang === 'fr' ? 'Informations' : data_lang === 'nl' ? 'Informatie' : 'Informations') +' :</span></u></strong></p>'
                + '<table border="1" cellpadding="1" cellspacing="1" class="w-100">'
                + '<tbody>'
                + '<tr>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Créé par' : data_lang === 'nl' ? 'Gemaakt door' : 'Created by') +' :</strong> ' + data_idcreatedat + '</td>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Créé à' : data_lang === 'nl' ? 'Gemaakt bij' : 'Created at') +' :</strong> ' + data_createdat + '</td>'
                + '</tr>'
                + '<tr>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Mis à jour par' : data_lang === 'nl' ? 'Bijgewerkt door' : 'Updated by') +' : </strong>' + data_idupdatedat + '</td>'
                + '<td><strong>'+ (data_lang === 'fr' ? 'Mis à jour à' : data_lang === 'nl' ? 'Bijgewerkt op' : 'Updated at') +' :</strong> ' + data_updatedat + '</td>'
                + '</tr>'
                + '</tbody>'
                + '</table>'
            )
        });
    });

}