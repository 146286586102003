export {init};

function init() {

    window.getCookie = function(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
    }
    var currentLang = getCookie('language');
    //console.log(currentLang)

    if (currentLang == 'nl_BE.utf-8') {
        require("datatables.net-bs4")(window, $);
        $('#list_compagnies').dataTable({
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json"
            }
        });
    }
    if (currentLang == 'en_US.utf-8') {
        require("datatables.net-bs4")(window, $);
        $('#list_compagnies').dataTable({
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json"
            }
        });
    }
    if (currentLang == 'fr_BE.utf-8') {
        require("datatables.net-bs4")(window, $);
        $('#list_compagnies').dataTable({
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
            }
        });
    }

    $('.input-daterange input').datepicker({
        format: 'dd/mm/yyyy',
        startDate: 'd',
        language: 'fr'
    });

}