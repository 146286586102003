export {init};

function init() {
    $('a[data-toggle=modal]').click(function () {
        var data_name = $(this).data('name') || '';
        var data_firstname = $(this).data('firstname') || '';
        var data_email = $(this).data('email') || '';
        var data_profile = $(this).data('profile') || '';
        var data_active = $(this).data('active') || '';
        var data_lang = $(this).data('lang') || 'fr';

        var active = data_active == 1 ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>';
        //*******/
        // USER */
        //*******/

        $('#confirm-delete').on('show.bs.modal', function(e) {
            $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
            $('.debug-url').html('Are you sure you want to delete: <strong>' + data_name + ' ' + data_firstname + '</strong>');
        });

        $('#view-details').on('show.bs.modal', function(e) {
            $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));

        $('.debug-url').html('Are you sure you want to delete: <strong>' + data_name + ' ' + data_firstname + '</strong>');
        $('.detailUser').html('<p><strong></strong></p>'
                            + '<hr />'
                            + '<table border="1" cellpadding="1" cellspacing="1" class="w-100">'
                            + '<tbody>'
                            + '<tr>'
                            + '<td><strong>'+ (data_lang === 'fr' ? 'Nom' : data_lang === 'nl' ? 'Naam' : 'Name') +' :</strong> ' + data_name +'</td>'
                            + '<td><strong>'+ (data_lang === 'fr' ? 'Prénom' : data_lang === 'nl' ? 'Voornaam' : 'First name') +' :</strong> ' + data_firstname + '</td>'
                            + '</tr>'
                            + '<tr>'
                            + '<td><strong>'+ (data_lang === 'fr' ? 'Profil' : data_lang === 'nl' ? 'Profiel' : 'Profile') +' :</strong> ' + data_profile + '</td>'
                            + '<td><strong>'+ (data_lang === 'fr' ? 'Actif' : data_lang === 'nl' ? 'Actief' : 'Active') +' :</strong><strong> </strong>' + active +'</td>'
                            + '</tr>'
                            + '<tr>'
                            + '<td colspan="2"><strong>'+ (data_lang === 'fr' ? 'Email' : data_lang === 'nl' ? 'E-mail' : 'Email') +' :</strong> ' + data_email + '</td>'
                            + '</tr>'
                            + '</tbody>'
                    +    '</table>')
        });
    });


    var currentLang = getCookie('language');

    if (currentLang == 'nl_BE') {
        require("datatables.net")(window, $);
        $('#list_users').dataTable({
            "stateSave": true,
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json"
            },
            "ordering": true,
            "order": [[ 6, "desc" ]],
            "columnDefs" : [{"targets": 6, "type": "date-uk"}],
        });
    }
    if (currentLang == 'en_US') {
        require("datatables.net")(window, $);
        $('#list_users').dataTable({
            "stateSave": true,
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json"
            },
            "ordering": true,
            "order": [[ 6, "desc" ]],
            "columnDefs" : [{"targets": 6, "type": "date-uk"}],
        });
    }
    if (currentLang == 'fr_BE') {
        require("datatables.net")(window, $);
        $('#list_users').dataTable({
            "stateSave": true,
            "language": {
                "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/French.json"
            },
            "ordering": true,
            "order": [[ 6, "desc" ]],
            "columnDefs" : [{"targets": 6, "type": "date-uk"}],
        });
    }

    $('.input-daterange input').datepicker({
        format: 'dd/mm/yyyy',
        startDate: 'd',
        language: 'fr'
    });
}

