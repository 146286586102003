export { init };

function init() {
  $(".search-job-input").keypress(function (e) {
    if (e && e.keyCode == 13) {
      document.forms[0].submit();
    }
  });

  $(".search-job-input").on("input", function () {
    $("#input-general-search").val(this.value);
    //console.log(this.value);
  });

  $("#jobs-go").click(function (e) {
    e.preventDefault();
    $("#formSubmitHome").trigger("submit");
  });

  $(".job-container .collapse").on("show.bs.collapse", function (e) {
    $(e.currentTarget).parent().css({
      background: "#D2E3F1",
      "border-color": "#77AAD4",
    });
    $(e.currentTarget).parent().find(".job-details").css({
      background: "#D2E3F1",
      "border-color": "#77AAD4",
    });
    $(e.currentTarget).parent().find(".job-header").css({
      background: "#D2E3F1",
      "border-color": "#77AAD4",
    });
  });

  $(".job-container .collapse").on("hidden.bs.collapse", function (e) {
    $(e.currentTarget).parent().css({
      background: "#F0F0F0",
      "border-color": "#959595",
    });
    $(e.currentTarget).parent().find(".job-details").css({
      background: "#F0F0F0",
      "border-color": "#959595",
    });
    $(e.currentTarget).parent().find(".job-header").css({
      background: "#F0F0F0",
      "border-color": "#959595",
    });
  });

  $(".logoBanner").click(function (e) {
    window.location.replace("/");
  });

  $("#modify-search, #new-search").click(function (e) {
    let category = $(this).attr("data-category");
    window.location.replace("/" + category + "s");
  });

  //Infinite scroll

  // var scrollLoad = true;

  // $(window).scroll(function () { 
  //   if (scrollLoad && $(window).scrollTop() >= $(document).height() - $(window).height() - 300) {
  //     scrollLoad = false;
  //     //Add something at the end of the page
  //     console.log("bottom!");

  //     $.ajax({
  //       method: "GET",
  //       url: "/getjobs",
  //       // data: $("#formSubmitHomeResponsive").serializeArray(),
  //     }).done(function (result) {
  //       const parsedData = JSON.parse(result);
  //       console.log(parsedData);
  //     });
  //   }
  // });

}
