import $ from "jquery";
import "bootstrap-fileinput";
import "bootstrap";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js";
import "bootstrap-fileinput/js/locales/fr.js";
import "jquery.maskedinput/src/jquery.maskedinput.js";
// import "tinymce/tinymce.min.js";

import "multiselect/js/jquery.multi-select.js";
import "multiselect-two-sides/dist/js/multiselect.min.js";
import "jquery.quicksearch/dist/jquery.quicksearch.min.js";

// import "select2/dist/js/select2.full.min.js";
import "fuzzysort/fuzzysort.js";
// import "multiselect-two-sides/dist/js/multiselect.min.js";
import "jquery.quicksearch/dist/jquery.quicksearch.min.js";
import "@google/markerclustererplus/dist/markerclustererplus.min.js";
import "nunjucks/browser/nunjucks.js";
// import "tinymce/tinymce.min.js";
// import "";

// import "flatpickr/dist/l10n/fr.js";
// import "flatpickr/dist/l10n/nl.js";

// var $  = require( 'jquery' );
var dt = require( 'datatables.net' )();

require( 'datatables.net-plugins/sorting/date-uk.js' );

// import "datatables.net-plugins/sorting/date-uk.js";

import * as datepicker_fr from "datepicker-fr";

// Admin view import
import * as adminView from "adminView";
import * as formLogin from "formLogin";

// Jobs view import
import * as lstJobs from "lstJobs";
import * as formAddJob from "formAddJob";
import * as formUpdJob from "formUpdJob";

// Internships view import
import * as lstInternships from "lstInternships";
import * as formAddInternship from "formAddInternship";
import * as formUpdInternship from "formUpdInternship";

// Users view import
import * as user from "user";
import * as lstUsers from "lstUsers";
import * as formAddUser from "formAddUser";
import * as formUpdUser from "formUpdUser";

// Company view import
import * as company from "company";
import * as formAddCompany from "formAddCompany";
import * as formUpdCompany from "formUpdCompany";
import * as lstCompany from "lstCompany";
import * as lstCompanyApplications from "lstCompanyApplications";

// Front view import
import * as frontView from "frontView";
import * as resultFrontView from "resultFrontView";
import * as jobsView from "jobsView";
import * as internshipsView from "internshipsView";

// Import Export view import
import * as impExpHome from "impExpHome";

import bsCustomFileInput from "bs-custom-file-input";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
// import { Dutch } from "flatpickr/dist/l10n/nl.js";

export { init };

/**
 *  Allow to change Language via the header dropdown menu.
 */
function languageNavBarHandlerInitialization() {
  // Change current lang when user click on header lang menu
  /*$('.lang-choice').on('click', function(event) {
        $('#language-menu-container > .lang-choice')
        var lang = $(event.currentTarget).attr('data-lang');
        var imgParent = event.currentTarget;
        var mainDIVV = document.getElementById("language-menu-container");
        imgParent.parentNode.removeChild(imgParent);
        mainDIVV.insertBefore(imgParent, mainDIVV.childNodes[0]);
        $.ajax({
            type: "POST",
            url: '/changelang/' + lang,
            data: {},
            success: function() {
                document.location.reload(true);
            },
        });
    })*/
  //   $(".lang-select-choice").on("change", function (event) {
  //     // var langSel = $('.lang-select-choice').children('option:selected').val();
  //     //var currentLangId = $('#current-lang').attr('data-lang');
  //     //var lang = $(event.currentTarget).attr('data-lang');
  //     var lang = $("option:selected", this).attr("data-lang");
  //     //debugger;
  //     $.ajax({
  //       type: "POST",
  //       url: "/changelang/" + lang,
  //       data: {},
  //       success: function () {
  //         document.location.reload(true);
  //       },
  //     });
  //   });

  $(".lang-select-choice .btn-language").on("click", function (event) {
    var lang = $(this).attr("data-lang");
    var path = $(this).attr("data-currentPath");
    console.log(lang, path);
    // document.location.replace(path + '?lang=' + lang);
    changeLanguage(lang);
  });

  function changeLanguage(lang) {
    $.ajax({
      type: "POST",
      url: "/changelang/" + lang,
      data: {},
      success: function () {
        document.location.reload(true);
      },
    });
  }
  /*$('#language-menu-container').hover(function (e) {
        $(this).css('height', 'auto');
        $(this).css('overflow', 'y-scroll');
        $(this).children(".lang-choice").removeClass("hidden");
        $('#container-ban-img').css('z-index','-1');
    }, function () {
        $(this).css('height', '30px');
        $(this).css('overflow', 'hidden');
        $(this).children(".lang-choice").addClass("hidden");
        $('#container-ban-img').css('z-index','1');
        var currentLangId = $('#current-lang').attr('data-lang');
        $("#language-menu-container div[data-lang-id='" + currentLangId + "']").removeClass("hidden");
    });*/

  // Select the current language in the header dropdown menu based on the coookies (if it does exist)
  var currentLang = getCookie("language");
  //console.log("current lang " + currentLang);

  // if (currentLang === 'en_US') {
  //     $('#choice_en').attr('selected', 'selected');
  // } else if (currentLang === 'nl_BE') {

  // } else {

  // }

  if (typeof currentLang !== undefined) {
    var buttonLang = $("#" + currentLang);
    buttonLang.addClass("active");
  }

  //console.log("element ", typeof currentLang !== undefined);
  //   if (typeof currentLang !== undefined) {
  //     var currentLangId = $("#" + currentLang).attr("data-langId");
  //     $(
  //       "#language-menu-container div[data-langId='" + currentLangId + "']"
  //     ).removeClass("hidden");
  //     // $('.img-flag-select').attr("src", "/public/images/front/" + currentLangId + ".png");
  //     //console.log(currentLangId);
  //     $(".lang-select-choice").val(currentLangId);
  //   }
  // else {
  //     $('#lang-select-choice').val(currentLangId);
  // }
}

// var oneYr = new Date();
// oneYr.setYear(now.getYear() + 1);
// var nextYear = oneYr.toString();

function flatPickerInitialization() {
  flatpickr("#flatpickr", {
    wrap: true,
    //minDate: new Date(),
    altInput: true,
    dateFormat: "Y-m-d H:i:S",
    altFormat: "d-m-Y",
    altField: "#alt-start-date",
    defaultDate: "today",
    weekNumbers: false,
    time_24hr: true,
    locale: French,
    onChange: function () {
      $("#starDateRequiredError").addClass("hidden");
    },
  });
  flatpickr("#flatpickr-end", {
    wrap: true,
    //minDate: new Date(),
    altInput: true,
    dateFormat: "Y-m-d H:i:S",
    defaultDate: new Date().fp_incr(365),
    altFormat: "d-m-Y",
    altField: "#alt-end-date",
    weekNumbers: false,
    time_24hr: true,
    //clickOpens: false,
    locale: French,
    onChange: function () {
      $("#endDateRequiredError").addClass("hidden");
    },
  });
  flatpickr("#flatpickr-upd", {
    wrap: true,
    //minDate: new Date(),
    altInput: true,
    dateFormat: "Y-m-d H:i:S",
    altFormat: "d-m-Y",
    weekNumbers: false,
    time_24hr: true,
    //clickOpens: false,
    locale: French,
  });
  flatpickr("#flatpickr-upd-end", {
    wrap: true,
    //minDate: new Date(),//.fp_incr(5),
    altInput: true,
    dateFormat: "Y-m-d H:i:S",
    altFormat: "d-m-Y",
    weekNumbers: false,
    time_24hr: true,
    //clickOpens: false,
    locale: French,
  });
}

function initHelperFunctions() {
  // Add a useful helper for cookies
  window.getCookie = function (name) {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
  };
}

/*function checkLangSmartSupp() {
    window.getCookie = function(name) {
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) return match[2];
    }
    var currentLang = getCookie('language');
    //console.log(currentLang)

    if (currentLang == 'nl_BE.utf-8') {
        smartsupp('language','nl');
    }
    if (currentLang == 'en_US.utf-8') {
        smartsupp('language','en');
    }
    if (currentLang == 'fr_BE.utf-8') {
        smartsupp('language','fr');
    }
}*/

function ResponsiveMenuView() {
  //View responsive
  if (window.matchMedia("(max-width: 768px)").matches) {
    $("#navbar-front-responsive").removeClass("hidden");
    $("#bannerdiv-front").addClass("hidden");
    $("#mtb-logo-responsive").removeClass("hidden");
    $("#mtb-logo").addClass("hidden");
    //Section on banner
    $(".responsive-container").removeClass("hidden");
    $(".top-container").addClass("hidden");
    $(".bottom-container").addClass("hidden");
    // $("#div-filter").addClass("hidden");
    $("#company-responsive").select2({
      theme: "bootstrap",
      //width: 100%
    });
  }
  //View not responsive
  if (window.matchMedia("(min-width: 768px)").matches) {
    $("#navbar-front-responsive").addClass("hidden");
    $("#bannerdiv-front").removeClass("hidden");
    $("#mtb-logo-responsive").addClass("hidden");
    $("#mtb-logo").removeClass("hidden");
    //Section on banner
    $(".responsive-container").addClass("hidden");
    $(".top-container").removeClass("hidden");
    $(".bottom-container").removeClass("hidden");
    // $("#div-filter").removeClass("hidden");
  }
}

function GenPdf(data) {
  //console.log(data);
  var idJob = data.idJob;
  var pdfName = data.pdfName;
  var pdfUrl = data.pdfUrl;
  var pdfHost = data.pdfHost;

  window.open("/public/data/pdf/" + pdfName, "_blank");
}

function handleClickPrintPDF(e, idjob, idlangjob) {
  //console.log(idjob);
  //console.log(idlangjob);
  var dataOfJob = $(e.currentTarget).parents().eq(3).html();
  e.preventDefault();
  var data = {
    idjob: idjob,
    idlangjob: idlangjob,
  };
  $.ajax({
    method: "POST",
    url: "/jobgenpdf",
    data: data,
    success: function (data) {
      try {
        var data = JSON.parse(data);
        //console.log(data);
      } catch (error) {
        console.error("Ajax answer malformed. Could not parse");
        return;
      }
      GenPdf(data);
    },
    error: function (error) {
      // handle error
    },
  });
}

function init() {
  $(document).ready(function (e) {
    initHelperFunctions();
    window.currentLang = getCookie("language");

    // Enable tooltip plugins
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    // Highligt nav element when choosen in header nav bar
    var url = window.location;
    $('ul.navbar a[href="' + url + '"]')
      .parent()
      .addClass("active");
    $("ul.navbar a")
      .filter(function () {
        return this.href == url;
      })
      .parent()
      .addClass("active");

    // input plugin
    bsCustomFileInput.init();

    // Get file and preview image
    $("#logo").on("change", function () {
      var input = $(this)[0];
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#preview").attr("src", e.target.result).fadeIn("slow");
        };
        reader.readAsDataURL(input.files[0]);
      }
    });

    //Click button print this job
    $(".btn-print").click(function (e) {
      //debugger
      e.preventDefault();
      var idjob = $(e.currentTarget).attr("data-id-job");
      var idlangjob = $(e.currentTarget).attr("data-lang");
      handleClickPrintPDF(e, idjob, idlangjob);
      //alert(id);
    });
    //Test When responsive menu display
    $(window).resize(ResponsiveMenuView);
    ResponsiveMenuView();

    languageNavBarHandlerInitialization();
    flatPickerInitialization();
    // addJobTabInitialization();
    //Check lang for smartsupp chat
    //checkLangSmartSupp();

    //_smartsupp.widget = "button";
    // $('.logoBanner, #new-search').click(function (e) {
    //     //window.location.replace('/');
    //     $.ajax({
    //         type: "POST",
    //         url: '/clearsession',
    //         success: function() {
    //             //window.location.replace('/');
    //             window.location.href = "/";
    //         },
    //     });
    // });

    $(".logoBanner").click(function (e) {
      //window.location.replace('/');
      $.ajax({
        type: "POST",
        url: "/clearsession",
        success: function () {
          //window.location.replace('/');
          window.location.href = "/";
        },
      });
    });

    var currentPageId = $("body").attr("data-page-id");

    switch (currentPageId) {
      // Main views
      case "adminView":
        adminView.init();
        break;
      case "formLogin":
        formLogin.init();
        break;
      // Other Views
      case "impExpHome":
        impExpHome.init();
        break;

      // Company views
      case "formAddCompany":
        formAddCompany.init();
        break;
      case "formUpdCompany":
        formUpdCompany.init();
        break;
      case "lstCompany":
        lstCompany.init();
        break;
      case "lstCompanyApplications":
        lstCompanyApplications.init();
        break;
      // Jobs views
      case "lstJobs":
        lstJobs.init();
        break;
      case "formAddJob":
        formAddJob.init();
        break;
      case "formUpdJob":
        formUpdJob.init();
        break;
        // Internships views
      case "lstInternships":
        lstInternships.init();
        break;
      case "formAddInternship":
        formAddInternship.init();
        break;
      case "formUpdInternship":
        formUpdInternship.init();
        break;
      // Users views
      case "lstUsers":
        lstUsers.init();
        break;
      case "formAddUser":
        formAddUser.init();
        break;
      case "formUpdUser":
        formUpdUser.init();
        break;
      // Front View
      case "frontView":
        frontView.init();
        break;
      case "jobsView":
        jobsView.init();
        break;
      case "internshipsView":
        internshipsView.init();
        break;
      case "resultFrontView":
        resultFrontView.init();
        break;
      default:
        break;
    }

    company.init();
    //front.init();
    user.init();
    // home.init();
    datepicker_fr.init();
    // input plugin
    bsCustomFileInput.init();

    // new WOW().init();

    var modal = $('#notificationModal');

    if (modal) {
      $('#notificationModal').modal('show');
    }
  });
}
