export { init };

function init() {
  //var data_google_js = JSON.parse($('#google-data').attr('data-google'));
  var data_google = $("#google-data").attr("data-google");
  var tmpdata = $("#google-data2").attr("data-googlen");
  if (tmpdata != "noChecked" && tmpdata != "") {
    var data_google_js2 = JSON.parse(tmpdata);
  }
  var tmpdataSessions = $("#google-dataSessions").attr(
    "data-googledataSessions"
  );
  if (tmpdataSessions != "noChecked" && tmpdataSessions != "") {
    var data_google_jsSessions = JSON.parse(tmpdataSessions);
  }
  var tmpdataUsers = $("#google-dataUsers").attr("data-googledataUsers");
  if (tmpdataUsers != "noChecked" && tmpdataUsers != "") {
    var data_google_jsUsers = JSON.parse(tmpdataUsers);
  }
  var tmpdataPagesViews = $("#google-dataPagesViews").attr(
    "data-googledataPagesViews"
  );
  if (tmpdataPagesViews != "noChecked" && tmpdataPagesViews != "") {
    var data_google_jsPagesViews = JSON.parse(tmpdataPagesViews);
  }
  var tmpdata2 = $("#google-data3").attr("data-googled");
  if (tmpdata2 != "noChecked" && tmpdata2 != "") {
    var data_google_js3 = JSON.parse(tmpdata2);
  }
  var tmpdata3 = $("#google-data4").attr("data-googlet");
  if (tmpdata3 != "noChecked" && tmpdata3 != "") {
    var data_google_js4 = JSON.parse(tmpdata3);
  }
  var tmpcompagniesSearch = $("#dataCompagniesForChart").attr(
    "data-compagniesSearch"
  );
  if (tmpcompagniesSearch != "noChecked" && tmpcompagniesSearch != "") {
    var data_compagniesSearch = JSON.parse(tmpcompagniesSearch);
  }
  var tmpJobTypeSearch = $("#dataJobTypeForChart").attr("data-JobTypeSearch");
  if (tmpJobTypeSearch != "noChecked" && tmpJobTypeSearch != "") {
    var data_JobTypeSearch = JSON.parse(tmpJobTypeSearch);
  }
  var tmpContractTypeSearch = $("#dataContractForChart").attr(
    "data-contractTypeSearch"
  );
  if (tmpContractTypeSearch != "noChecked" && tmpContractTypeSearch != "") {
    var data_ContractTypeSearch = JSON.parse(tmpContractTypeSearch);
  }
  var tmpSearch = $("#dataSearchForChart").attr("data-Search");
  if (tmpSearch != "noChecked" && tmpSearch != "") {
    var data_Search = JSON.parse(tmpSearch);
  }
  var tmpSearchByCrit = $("#dataSearchByCritForChart").attr(
    "data-Search-ByCrit"
  );
  if (tmpSearchByCrit != "noChecked" && tmpSearchByCrit != "") {
    var data_SearchByCrit = JSON.parse(tmpSearchByCrit);
  }

  //console.log(data_google_js2);
  //console.log(data_google_js);
  //console.log(data_google_js4);
  //console.log(data_JobTypeSearch);

  /**************
   * CHART 1    *
   * ************/
  if (data_google_js2 !== "") {
    var chart = am4core.createFromConfig(
      {
        // Create pie series
        series: [
          {
            type: "PieSeries",
            dataFields: {
              value: "sessions",
              category: "week",
            },
          },
        ],

        // Add data
        data: data_google_js2,

        // And, for a good measure, let's add a legend
        legend: {},
      },
      "chartdiv",
      am4charts.PieChart
    );
  }

  /**************
   * CHART 2    *
   * ************/

  // Create chart instance
  /*var chart = am4core.create("chartdiv2", am4charts.PieChart);

  // Add data
  chart.data = data_google_js2;

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.name = "country";
  pieSeries.dataFields.value = "sessions";
  pieSeries.dataFields.category = "country";


  // Legend
  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  var markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 40;
  markerTemplate.height = 40;
  markerTemplate.stroke = am4core.color("#ccc");*/

  /**************
   * CHART 3    *
   * ************/
  //console.log("data_google_js2 -> ");
  //console.log(data_google_js2);
  // Create chart instance
  var chart = am4core.create("chartdiv3", am4charts.XYChart);

  // Increase contrast by taking evey second color
  chart.colors.step = 2;

  // Add data
  chart.data = data_google_js2;

  // Create axes
  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "week";
  categoryAxis.title.text = "Week";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 20;

  // Create series
  function createAxisAndSeries(field, name, opposite, bullet) {
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (chart.yAxes.indexOf(valueAxis) != 0) {
      valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    }
    valueAxis.title.text = "Number of sessions";

    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "week";
    //thickness trait
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;
    series.showOnInit = true;

    var interfaceColors = new am4core.InterfaceColorSet();

    switch (bullet) {
      case "triangle":
        var bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 12;
        bullet.height = 12;
        bullet.horizontalCenter = "middle";
        bullet.verticalCenter = "middle";

        var triangle = bullet.createChild(am4core.Triangle);
        triangle.stroke = interfaceColors.getFor("background");
        triangle.strokeWidth = 2;
        triangle.direction = "top";
        triangle.width = 12;
        triangle.height = 12;
        break;
      case "rectangle":
        var bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 10;
        bullet.height = 10;
        bullet.horizontalCenter = "middle";
        bullet.verticalCenter = "middle";

        var rectangle = bullet.createChild(am4core.Rectangle);
        rectangle.stroke = interfaceColors.getFor("background");
        rectangle.strokeWidth = 2;
        rectangle.width = 10;
        rectangle.height = 10;
        break;
      default:
        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = interfaceColors.getFor("background");
        bullet.circle.strokeWidth = 2;
        break;
    }

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;
  }

  createAxisAndSeries("sessions", "sessions", false, "circle");
  createAxisAndSeries("users", "users", true, "triangle");
  createAxisAndSeries("pageviews", "pageviews", true, "rectangle");

  // Add legend
  chart.legend = new am4charts.Legend();

  // Add cursor
  chart.cursor = new am4charts.XYCursor();

  /**************
   * CHART Sessions    *
   * ************/

  //am4core.useTheme(am4themes_animated);
  // Create chart instance
  var chart = am4core.create("chartdivSessions", am4charts.XYChart);

  // Load data
  chart.data = data_google_jsSessions;
  //chart.dataSource.url = "data.php";

  // Create axes
  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "week";
  categoryAxis.title.text = "Week";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 20;

  // Create series
  function createAxisAndSeries(field, name, opposite, bullet) {
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (chart.yAxes.indexOf(valueAxis) != 0) {
      valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    }
    valueAxis.title.text = "Number of sessions";

    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "week";
    //thickness trait
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.tensionX = 0.8;
    series.showOnInit = true;

    var interfaceColors = new am4core.InterfaceColorSet();

    switch (bullet) {
      case "triangle":
        var bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 12;
        bullet.height = 12;
        bullet.horizontalCenter = "middle";
        bullet.verticalCenter = "middle";

        var triangle = bullet.createChild(am4core.Triangle);
        triangle.stroke = interfaceColors.getFor("background");
        triangle.strokeWidth = 2;
        triangle.direction = "top";
        triangle.width = 12;
        triangle.height = 12;
        break;
      case "rectangle":
        var bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 10;
        bullet.height = 10;
        bullet.horizontalCenter = "middle";
        bullet.verticalCenter = "middle";

        var rectangle = bullet.createChild(am4core.Rectangle);
        rectangle.stroke = interfaceColors.getFor("background");
        rectangle.strokeWidth = 2;
        rectangle.width = 10;
        rectangle.height = 10;
        break;
      default:
        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = interfaceColors.getFor("background");
        bullet.circle.strokeWidth = 2;
        break;
    }

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;
  }

  createAxisAndSeries("sessions", "sessions", false, "circle");
  //createAxisAndSeries("users", "users", true, "triangle");
  //createAxisAndSeries("pageviews", "pageviews", true, "rectangle");

  // Add legend
  chart.legend = new am4charts.Legend();

  // Add cursor
  chart.cursor = new am4charts.XYCursor();

  /**************
   * CHART Users *
   * ************/

  //am4core.useTheme(am4themes_animated);
  // Create chart instance
  var chart = am4core.create("chartdivUsers", am4charts.XYChart);

  // Load data
  chart.data = data_google_jsUsers;
  //chart.dataSource.url = "data.php";

  // Create axes
  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "week";
  categoryAxis.title.text = "Week";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 20;

  var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = "Number of users";

  // Create series
  function createSeries(field, name) {
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "week";
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.7;
    series.columns.template.strokeWidth = 0;

    var hs = series.columns.template.states.create("active");
    hs.properties.fillOpacity = 1;

    series.columns.template.events.on("hit", highlighColumn);

    return series;
  }

  createSeries("users", "Users");

  // Add events
  categoryAxis.renderer.labels.template.events.on("hit", highlighColumn);
  categoryAxis.renderer.labels.template.cursorOverStyle =
    am4core.MouseCursorStyle.pointer;

  function highlighColumn(ev) {
    chart.series.each(function (series) {
      if (series instanceof am4charts.ColumnSeries) {
        series.columns.each(function (column) {
          if (column.dataItem.categoryX == ev.target.dataItem.category) {
            column.isActive = true;
          } else {
            column.isActive = false;
          }
        });
      }
    });
  }

  /******************
   * CHART PageViews *
   * *****************/

  //am4core.useTheme(am4themes_animated);
  // Create chart instance
  var chart = am4core.create("chartdivPagesViews", am4charts.XYChart);

  // Load data
  chart.data = data_google_jsPagesViews;
  //chart.dataSource.url = "data.php";

  // Create axes
  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "week";
  categoryAxis.title.text = "Week";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 20;

  var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = "Number of Page views";

  // Create series
  function createSeries(field, name) {
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "week";
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.7;
    series.columns.template.strokeWidth = 0;

    var hs = series.columns.template.states.create("active");
    hs.properties.fillOpacity = 1;

    series.columns.template.events.on("hit", highlighColumn);

    return series;
  }

  createSeries("pageviews", "Pageviews");

  // Add events
  categoryAxis.renderer.labels.template.events.on("hit", highlighColumn);
  categoryAxis.renderer.labels.template.cursorOverStyle =
    am4core.MouseCursorStyle.pointer;

  function highlighColumn(ev) {
    chart.series.each(function (series) {
      if (series instanceof am4charts.ColumnSeries) {
        series.columns.each(function (column) {
          if (column.dataItem.categoryX == ev.target.dataItem.category) {
            column.isActive = true;
          } else {
            column.isActive = false;
          }
        });
      }
    });
  }

  /**************
   * CHART 4    *
   * ************/

  // Create chart instance
  var chart = am4core.create("chartdiv4", am4charts.PieChart);

  // Add data
  chart.data = data_google_js3;

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.name = "browser";
  pieSeries.dataFields.value = "sessions";
  pieSeries.dataFields.category = "browser";

  // Legend
  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  var markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 40;
  markerTemplate.height = 40;
  markerTemplate.stroke = am4core.color("#ccc");

  /**************
   * CHART 5    *
   * ************/

  // Create chart instance
  /*var chart = am4core.create("chartdiv5", am4charts.XYChart);

  // Load data
  chart.data = data_google_js4;
  //chart.dataSource.url = "data.php";

  // Create axes
  var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "pagePath";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 30;
  categoryAxis.title.text = "Page path";
  categoryAxis.title.fontWeight = "bold";

  var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = "Pageviews";
  valueAxis.title.fontWeight = "bold";
  /*var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis2.title.text = "Pageviews";
  valueAxis2.title.fontWeight = "bold";
  valueAxis2.renderer.opposite = true;
  valueAxis2.renderer.grid.template.disabled = true;*/

  // Create series
  /*var series = chart.series.push(new am4charts.ColumnSeries3D());
  series.dataFields.valueY = "pageviews";
  series.dataFields.categoryX = "pagePath";
  series.name = "Page views";

  /*var series2 = chart.series.push(new am4charts.ColumnSeries());
  series2.dataFields.valueY = "Page path";
  series2.dataFields.categoryX = "Page path";
  series2.name = "Page path";*/

  /*var series3 = chart.series.push(new am4charts.LineSeries());
  series3.dataFields.valueY = "pageviews";
  series3.dataFields.categoryX = "Page path";
  series3.name = "Pageviews";
  series3.strokeWidth = 3;
  series3.yAxis = valueAxis2;

  var bullet3 = series3.bullets.push(new am4charts.CircleBullet());
  bullet3.circle.radius = 5;
  bullet3.circle.stroke = am4core.color("#fff");
  bullet3.circle.strokeWidth = 2;*/

  // Add legend
  //chart.legend = new am4charts.Legend();

  /**************
   * CHART 6    *
   * ************/

  // Create chart instance
  var chart = am4core.create("chartdiv6", am4charts.PieChart);

  // Add data
  chart.data = data_compagniesSearch;

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.name = "Company";
  pieSeries.dataFields.value = "Search";
  pieSeries.dataFields.category = "Company";

  // Legend
  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  var markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 40;
  markerTemplate.height = 40;
  markerTemplate.stroke = am4core.color("#ccc");

  /**************
   * CHART 7    *
   * ************/

  // Create chart instance
  var chart = am4core.create("chartdiv7", am4charts.PieChart);

  // Add data
  chart.data = data_JobTypeSearch;

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.name = "TypeJob";
  pieSeries.dataFields.value = "Search";
  pieSeries.dataFields.category = "TypeJob";

  // Legend
  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  var markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 40;
  markerTemplate.height = 40;
  markerTemplate.stroke = am4core.color("#ccc");

  /**************
   * CHART 8    *
   * ************/

  // Create chart instance
  var chart = am4core.create("chartdiv8", am4charts.PieChart);

  // Add data
  chart.data = data_ContractTypeSearch;

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.name = "ContractType";
  pieSeries.dataFields.value = "Search";
  pieSeries.dataFields.category = "ContractType";

  // Legend
  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  var markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 40;
  markerTemplate.height = 40;
  markerTemplate.stroke = am4core.color("#ccc");

  /**************
   * CHART 9     *
   * ************/

  // Create chart instance
  var chart = am4core.create("chartdiv9", am4charts.PieChart);

  // Add data
  chart.data = data_Search;

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.name = "Type";
  pieSeries.dataFields.value = "number";
  pieSeries.dataFields.category = "Type";

  // Legend
  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  var markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 40;
  markerTemplate.height = 40;
  markerTemplate.stroke = am4core.color("#ccc");

  /**************
   * CHART 10     *
   * ************/

  // Create chart instance
  var chart = am4core.create("chartdiv10", am4charts.PieChart);

  // Add data
  chart.data = data_SearchByCrit;

  // Add and configure Series
  var pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.name = "libel";
  pieSeries.dataFields.value = "number";
  pieSeries.dataFields.category = "libel";

  // Legend
  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  var markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 40;
  markerTemplate.height = 40;
  markerTemplate.stroke = am4core.color("#ccc");
}
