// import { debug } from "webpack";

export {init};

var companyListAndName = [];
var circleDrawn = null;
var geocoder;
var radiusDropDownIsOpenWatchDog = 0;
var localizationState = "";
var customizedCoordinate = undefined;


//NON Responsive
/**
 * Used for limit request number
 */
var updateJobCountWatchDog = {
    mustCheck: false,
    checkAt: Date.now(),

    reset: function() {
        this.mustCheck = true;
        this.checkAt = Date.now() + 1000;
    },
    check: function() {
        if (!this.mustCheck) {
            return false;
        }
        if (Date.now() > this.checkAt) {
            this.mustCheck = false;
            $.ajax({
                method: "POST",
                url: "/getjobcount",
                data: $('#formSubmitHome').serializeArray()
            })
                .done(function (result) {
                    try {
                        var result = JSON.parse(result);
                    } catch (error) {
                        console.error('Ajax answer malformed. Could not parse');
                    }
                    if (result.error) {
                        switch (result.error_code) {
                            default:
                                console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                                break;
                        }
                        return;
                    }
                    $('#jobs-go > span').addClass('hidden');

                    var jobCount = result.data.jobCount;
                    if (jobCount == 0) {
                        $('#jobs-go > .zero').removeClass('hidden');
                        $('#jobs-go').prop('disabled', true);
                    }
                    if (jobCount == 1) {
                        $('#jobs-go > .one').removeClass('hidden');
                        $('#jobs-go').prop('disabled', false);
                    }
                    if (jobCount > 1) {
                        $('#jobs-go > .many').removeClass('hidden');
                        $('#jobs-go').prop('disabled', false);
                        $('#jobs-go .nbrjobs').html(jobCount);
                    }
                });
        }
    },
}

//Responsive
/**
 * Used for limit request number
 */
var updateJobCountWatchDogResponsive = {
    mustCheck: false,
    checkAt: Date.now(),

    reset: function() {
        this.mustCheck = true;
        this.checkAt = Date.now() + 1000;
    },
    check: function() {
        if (!this.mustCheck) {
            return false;
        }
        if (Date.now() > this.checkAt) {
            this.mustCheck = false;
            $.ajax({
                method: "POST",
                url: "/getjobcount",
                data: $('#formSubmitHomeResponsive').serializeArray()
            })
                .done(function (result) {
                    try {
                        var result = JSON.parse(result);
                    } catch (error) {
                        console.error('Ajax answer malformed. Could not parse');
                    }
                    if (result.error) {
                        switch (result.error_code) {
                            default:
                                console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                                break;
                        }
                        return;
                    }
                    $('#jobs-go-responsive > span').addClass('hidden');
                    var jobCount = result.data.jobCount;
                    if (jobCount == 0) {
                        $('#jobs-go-responsive > .zero').removeClass('hidden');
                        $('#jobs-go-responsive').prop('disabled', true);
                    }
                    if (jobCount == 1) {
                        $('#jobs-go-responsive > .one').removeClass('hidden');
                        $('#jobs-go-responsive').prop('disabled', false);
                    }
                    if (jobCount > 1) {
                        $('#jobs-go-responsive > .many').removeClass('hidden');
                        $('#jobs-go-responsive').prop('disabled', false);
                        $('#jobs-go-responsive .nbrjobs').html(jobCount);
                    }
                });
        }
    },
}

function capitalizeFirstLetter([ first, ...rest ], locale = navigator.language) {
    try {
        return [first.toLocaleUpperCase(locale), ...rest].join('');
    } catch (error) {
        return ''
    }
}

function updateCompanyCheckboxs() {
    if (window.matchMedia('(max-width: 768px)').matches) {
        updateJobCountWatchDogResponsive.reset();
    } else {
        updateJobCountWatchDog.reset();
    }
    var checkboxes = $('.container-choice-company input[type="checkbox"]');
    var countCheckedCheckboxes = checkboxes.filter(function () {
        return !this.disabled && this.checked;
    }).length;

    /*var countCheckedCheckboxesuncheck = checkboxes.filter(function () {
        return !this.disabled && this.unchecked;
    }).length;*/

    $('#nbrcompsel').text(countCheckedCheckboxes);
    if (countCheckedCheckboxes >= 1) {
        $('.filter-result-company-yes').removeClass('hiden');
        $('.filter-result-company-no').addClass('hiden');
        $('#img-company-off-left').addClass('hiden')
        $('#img-company-on-left').removeClass('hiden');
        $('#img-company-off-result').addClass('hiden');
        $('#img-company-on-result').removeClass('hiden');
    }
    //$('.filter-result-company-yes').text(countCheckedCheckboxes);
    $('.count-comp').text(countCheckedCheckboxes);
    $('.no-data-company').addClass('hiden');
    $('.yes-data-company').removeClass('hiden');
    $('.company_selected-result').text(countCheckedCheckboxes);
}

$(document).ready(function() {
    if (window.matchMedia('(max-width: 768px)').matches) {
        updateJobCountWatchDogResponsive.reset();
    } else {
        updateJobCountWatchDog.reset();
    }
});

/**
 * Google Map management
 */
function removeCircle(circle) {
    google.maps.event.clearListeners(circle, 'click_handler_name');
    google.maps.event.clearListeners(circle, 'drag_handler_name');
    circle.setMap(null);
}

function drawCircle(rad, center) {
    rad *= 1000; // convert to km

    if (circleDrawn !== null) {
        removeCircle(circleDrawn);
    }

    circleDrawn = new google.maps.Circle({
        center: center,
        editable: false,
        clickable: false,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: map,
        radius: rad,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
    });
}

var map, positionMarker;

//NON Responsive
function onRadiusChange() {
    updateJobCountWatchDog.reset();
    if (customizedCoordinate) {
        var translatedAroundThisPointText = $('#around-this-point').html();
        var lat = parseFloat($('#lat').val());
        var lng = parseFloat($('#lng').val());
        $('.localization-selected').html(translatedAroundThisPointText);
        $('.localization-selected > .latitude').text(lat.toFixed(3) + '...');
        $('.localization-selected > .longitude').text(lng.toFixed(3) + '...');
        drawCircle($('#input-dis').val(), positionMarker.position);
    } else {
        $('#img-region-off-left').addClass('hiden');
        $('#img-region-on-left').removeClass('hiden');

        var thisPlace = capitalizeFirstLetter($('#inputWhereSearch-ong1').val());
        var translatedAroundThisPlace = $('#around-this-place').html();
        $('.localization-selected').html(translatedAroundThisPlace);
        $('.localization-selected > .this-place').text(thisPlace);
        drawCircle($('#input-dis').val(), positionMarker.position);
    }

    var radius = parseInt($('#input-dis').val());
    $('.localization-selected > .km-radius').text(radius);

    var finalText = $('.localization-selected').text().trim();
    $('#localization-selected').val(finalText);
}

//Responsive
function onRadiusChangeResponsive() {
    updateJobCountWatchDogResponsive.reset();

    var thisPlace = capitalizeFirstLetter($('#inputWhereSearch-ong1-responsive').val());
    var translatedAroundThisPlace = $('#around-this-place').html();
    $('.localization-selected-responsive').html(translatedAroundThisPlace);
    $('.localization-selected-responsive > .this-place').text(thisPlace);
    drawCircle($('#input-dis-responsive').val(), positionMarker.position);

    var radius = parseInt($('#input-dis-responsive').val());
    $('.localization-selected-responsive > .km-radius').text(radius);

    var finalText = $('.localization-selected-responsive').text().trim();
    $('#localization-selected-responsive').val(finalText);
}

function handleGeocodeRequest(results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
        updateJobCountWatchDog.reset();
        var image = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
        var pos = results[0].geometry.location;
        map.setCenter(pos);
        map.setZoom(10);
        positionMarker.setPosition(pos);
        positionMarker.setIcon(image);
        $('#lat').val(pos.lat());
        $('#lng').val(pos.lng());
        var radius = parseInt($('#input-dis').val());
        if (radius) {
            $('#rad').val(radius);
            drawCircle(radius, pos);
        }
    }
}

function handleGeocodeRequestResponsive(results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
        updateJobCountWatchDogResponsive.reset();
        var pos = results[0].geometry.location;
        map.setCenter(pos);
        map.setZoom(10);
        positionMarker.setPosition(pos);
        $('#lat-responsive').val(pos.lat());
        $('#lng-responsive').val(pos.lng());
        var radius = parseInt($('#input-dis-responsive').val());
        if (radius) {
            $('#rad-responsive').val(radius);
            drawCircle(radius, pos);
        }
    }
}

function onMapClick(event) {
    updateJobCountWatchDog.reset();
    if (Date.now() < radiusDropDownIsOpenWatchDog) {
        return;
    }
    positionMarker.setPosition(event.latLng);
    var lat = event.latLng.lat();
    var lng = event.latLng.lng()
    $('#lat').val(lat);
    $('#lng').val(lng);
    var radius = parseInt($('#input-dis').val());
    if (radius) {
        $('#rad').val(radius);
        drawCircle(radius, event.latLng);
    }
    customizedCoordinate = true;
    $('#input-dis').prop('disabled', false);

    var translatedAroundThisPointText = $('#around-this-point').html();
    $('.localization-selected').html(translatedAroundThisPointText);
    $('.localization-selected > .km-radius').text(radius);
    $('.localization-selected > .latitude').text(lat.toFixed(3) + '...');
    $('.localization-selected > .longitude').text(lng.toFixed(3) + '...');
    var finalText = $('.localization-selected').text().trim();
    $('#localization-selected').val(finalText);
}


function initGoogleMap() {
    map = new google.maps.Map(document.getElementById('gmap'), {
        zoom: 8,
        center: new google.maps.LatLng(
            50.844846542870556,
            4.346999790039052
        ),
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
    });

    positionMarker = new google.maps.Marker({
        position    : undefined,
        map: map,
        title: '',
        label: '',
        icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
    });

    geocoder = new google.maps.Geocoder();

    for (var i = 0; i < locs.length; i++) {
        var loc = locs[i];

        var latLng = new google.maps.LatLng(
            loc.lat,
            loc.lng
        );

        var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            title: loc.id_job,
            label: '',
            icon: {
                url: "/public/images/front/m1.png",
                anchor: new google.maps.Point(26, 26)
            }

        });

        google.maps.event.addListener(marker, 'click', onMapClick);
    }

    //NON Responsive
    $('#inputWhereSearch-ong1').on('change', function () {
        updateJobCountWatchDog.reset();
        if ($('#inputWhereSearch-ong1').length) {
            var request = {
                address: $('#inputWhereSearch-ong1').val()
            }
            geocoder.geocode(request, handleGeocodeRequest);
        }
        $('#img-region-off-left').addClass('hiden')
        $('#img-region-on-left').removeClass('hiden');
        $('.no-data-region').addClass('hiden');
        $('.yes-data-region').removeClass('hiden');
        $('#img-region-off-result').addClass('hiden');
        $('#img-region-on-result').removeClass('hiden');
        $('.filter-save-country').text($(this).val());

        customizedCoordinate = false;
        $('#input-dis').prop('disabled', false);
        var thisPlace = capitalizeFirstLetter($('#inputWhereSearch-ong1').val());
        var radius = parseInt($('#input-dis').val());
        var translatedAroundThisPointText = $('#around-this-place').html();
        $('.localization-selected').html(translatedAroundThisPointText);
        $('.localization-selected > .km-radius').text(radius);
        $('.localization-selected > .this-place').text(thisPlace);

        var finalText = $('.localization-selected').text().trim();
        $('#localization-selected').val(finalText);
    });

    $('#inputWhereSearch-ong1').keypress(function (e) {
        if (e.keyCode === 13) {
            var request = {
                address: $(this).val()
            }

            geocoder.geocode(request, handleGeocodeRequest);
            return false;                                      // Why ?
        }
    })

    $('#inputWhereSearch-ong1').each(function () {
        if ($(this).val() !== '') {
            var request = {
                address: $('#inputWhereSearch-ong1').val()
            }

            geocoder.geocode(request, handleGeocodeRequest);
        }
    });

    $('#input-dis').on('change', onRadiusChange);

    //Responsive
    $('#inputWhereSearch-ong1-responsive').on('change', function () {
        updateJobCountWatchDogResponsive.reset();
        if ($('#inputWhereSearch-ong1-responsive').length) {
            var request = {
                address: $('#inputWhereSearch-ong1-responsive').val()
            }
            geocoder.geocode(request, handleGeocodeRequestResponsive);
        }
    });
    $('#inputWhereSearch-ong1-responsive').each(function () {
        if ($(this).val() !== '') {
            var request = {
                address: $('#inputWhereSearch-ong1-responsive').val()
            }

            geocoder.geocode(request, handleGeocodeRequestResponsive);
        }
    });
    $('#input-dis-responsive').on('change', onRadiusChangeResponsive);

    /**
     * Note: Google Maps `click` event is maybe not what you think it is. This event is only
     * trigged on Mouse UP/Touch End iff there was no drag after Mouse DOWN.
     *
     * See also: https://developers.google.com/maps/documentation/javascript/events
     */
    google.maps.event.addListener(map, 'click', onMapClick);
}

function filterTabInitialization() {
    //Tab1 hover animation
    $('.tab1').hover(function (e) {
        $(this).addClass('transition');
        $('#img-reg-on').addClass('hiden');
        $('#img-reg-off').removeClass('hiden');
        $('.rowong1').css('color', '#fff');
        $('.localization-selected').css({'color': '#FFFFFF'});
    }, function () {
        $(this).removeClass('transition');
        $('#img-reg-off').addClass('hiden');
        $('#img-reg-on').removeClass('hiden');
        $('.rowong1').css('color', '#503105');
        $('.localization-selected').css({'color': '#503105'});
    });

    //Tab2 hover animation
    $('.tab2').hover(function (e) {
        $(this).addClass('transition');
        $('#img-job-on').addClass('hiden');
        $('#img-job-off').removeClass('hiden');
        $('.rowong2').css('color', '#fff');
        $('.job-filter-selected').css({ 'color': '#FFFFFF' });
    }, function () {
        $(this).removeClass('transition');
        $('#img-job-off').addClass('hiden');
        $('#img-job-on').removeClass('hiden');
        $('.rowong2').css('color', '#14384A');
        $('.job-filter-selected').css({ 'color': '#14384A' });
    });

    //Tab3 hover animation
    $('.tab3').hover(function (e) {
        $(this).addClass('transition');
        $('.rowong3').css('color', '#fff');
        $('#img-soc-on').addClass('hiden');
        $('#img-soc-off').removeClass('hiden');
        $('.filter-result-company-no').css({ 'color': '#FFFFFF' });
    }, function () {
        $(this).removeClass('transition');
        $('#img-soc-off').addClass('hiden');
        $('#img-soc-on').removeClass('hiden');
        $('.rowong3').css('color', '#153814');
            $('.filter-result-company-no').css({ 'color': '#153814' });
    });

    $('.tab1').click(function (e) {
        e.preventDefault();
        $('.onglet-2').hide('slow');
        $('.onglet-3').hide('slow');
        $('.onglet-1').show('slow');
        $('.onglet-1').css('display', 'flex');
    });

    $('.tab2').click(function (e) {
        e.preventDefault();
        $('.onglet-1').hide('slow');
        $('.onglet-3').hide('slow');
        $('.onglet-2').show('slow');
        $('.onglet-2').css('display', 'flex');
    });

    $('.tab3').click(function (e) {
        e.preventDefault();
        $('.onglet-2').hide('slow');
        $('.onglet-1').hide('slow');
        $('.onglet-3').show('slow');
        $('.onglet-3').css('display', 'flex');
    });
}


function resetTabInitilization() {
    // Reset button
    $('#button-ong-1').click(function (e) {
        e.preventDefault();
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
        $('#inputWhereSearch-ong1').val('');
        $('#img-region-off-left').removeClass('hiden');
        $('#img-region-on-left').addClass('hiden');
        $('#img-region-off-result').removeClass('hiden');
        $('#img-region-on-result').addClass('hiden');
        $('.result-distance').addClass('hiden');
        $('.result-country').addClass('hiden');
        $('.no-data-region').removeClass('hiden');

        // Reset radius
        $('#input-dis').val('20');
        $('#input-dis').trigger('change');

        // Reset Google Map
        map.panTo(new google.maps.LatLng(
            50.844846542870556,
            4.346999790039052
        ));
        map.setZoom(8);
        removeCircle(circleDrawn);
        $('#lat').val('');
        $('#lng').val('');
        positionMarker.setMap(null);
    });

    $('#button-ong-2').click(function (e) {
        e.preventDefault();
        //responsive
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
        $('#inlineCheckbox1').trigger("click");
        $('#inlineCheckbox2').trigger("click");
        $('#inlineCheckbox3').trigger("click");
        //$('.job-filter-selected').empty();
        //todo A faire autrement
        var idLang = $('#current-lang').attr('data-lang');
        if (idLang == 1 ) {
            $('.job-filter-selected').text('Aucune donnée sélectionnée');
        }
        if (idLang == 2 ) {
            $('.job-filter-selected').text('Geen gegevens geselecteerd');
        }
        if (idLang == 3 ) {
            $('.job-filter-selected').text('No data selected');
        }
        //$('.job-filter-selected').text($('#contract_type').find(":checked").text() + ", " + $('#job_type').find(":checked").text() );
        //$('.job-filter-selected').text($('#contract_type').find(":checked").text() + ", " + $(this).find(":checked").text() );
        $('#contract_type').val('');
        $('#job_type').val('');
        $('#img-job-off-left').removeClass('hiden');
        $('#img-job-on-left').addClass('hiden');
        $('#img-job-off-result').removeClass('hiden');
        $('#img-job-on-result').addClass('hiden');
        $('.result-job').addClass('hiden');
        $('.result-contract').addClass('hiden');
        $('.no-data-job').removeClass('hiden');
    });

    $('#button-ong-3').click(function (e) {
        e.preventDefault();
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
        $('#img-company-off-left').removeClass('hiden')
        $('#img-company-on-left').addClass('hiden');
        $('#img-company-off-result').removeClass('hiden');
        $('#img-company-on-result').addClass('hiden');
        //$('.filter-result-company-yes').text(0);
        $('.count-comp').text(0);
        $('.no-data-company').removeClass('hiden');
        $('.yes-data-company').addClass('hiden');
        $('.company_selected-result').text(0);
        $('#nbrcompsel').text(0);
        $('input:checkbox').removeAttr('checked');
        $(".testo").prop('checked', false);
        $('#inputCompanySearch').val('');
        $('#inputCompanySearch').trigger('keyup');
        $('.filter-result-company-yes').addClass('hiden');
        $('.filter-result-company-no').removeClass('hiden');
    });
}


/**
 *  Search in company list
 */
function initFuzzySearchCompanyListFilter() {
    var companyElements = $('.container-choice-company');
    for (var i = 0; i < companyElements.length; i++) {
        let companyName = $($('.container-choice-company')[i]).text().trim();
        let id = $($('.container-choice-company')[i]).find('input').val();
        companyListAndName[companyName] = {
            id: id,
            score: 0
        }
    }

    var delay = (function () {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })();

    $('#inputCompanySearch').keyup(function () {
        delay(function () {
            var actualValue = $('#inputCompanySearch').val().trim();
            if (actualValue === '') {
                $('.container-choice-company').css({ display: 'flex' });
                $('.container-choice-company > input').prop('disabled', false);
                updateCompanyCheckboxs();
                return;
            }

            Object.keys(companyListAndName).forEach(function (key) {
                var result = fuzzysort.single(actualValue, key);
                if (result !== null) {
                    companyListAndName[key].score = result.score;
                } else {
                    companyListAndName[key].score = -500000000;
                }
            });

            var arr = [];
            var firstKey = Object.keys(companyListAndName)[0];
            var minScore = companyListAndName[firstKey].score;
            var maxScore = companyListAndName[firstKey].score;

            Object.keys(companyListAndName).forEach(function (key) {
                var score = companyListAndName[key].score;
                minScore = (score < minScore) ? score : minScore;
                maxScore = (score < maxScore) ? score : maxScore;
                arr.push({
                    id: companyListAndName[key].id,
                    score: score,
                });
            });

            arr.forEach(function (elem) {
                if (elem.score !== null && elem.score > -1000) {
                    $(".container-choice-company > input[value=" + elem.id + "]").parent().css({ display: 'flex' });
                    $(".container-choice-company > input[value=" + elem.id + "]").prop('disabled', false);
                } else {
                    $(".container-choice-company > input[value=" + elem.id + "]").parent().css({ display: 'none' });
                    $(".container-choice-company > input[value=" + elem.id + "]").prop('disabled', true);
                }
                updateCompanyCheckboxs();
            });
        }, 200);
    });
}


function init() {
    //General search
    $('.search-job-input').keypress(function(e){
        if (e && e.keyCode == 13) {
            document.forms[1].submit();
        }
    });

    $('.search-job-input').on("input", function () {
        $('#input-general-search').val(this.value);
    });

    $('#input-dis').select2({
        tags: true,
        theme: "bootstrap",
        selectOnClose: true
    });
    $('#input-dis-responsive').select2({
        tags: true,
        theme: "bootstrap",
        selectOnClose: true
    });

    /*$('.logoBanner, #new-search').click(function(e) {
        window.location.replace('/');
    });*/

    $('#jobs-go').click(function(e){
        e.preventDefault();
        $('#formSubmitHome').trigger('submit');
    })
    $('#jobs-go-responsive').click(function(e){
        e.preventDefault();
        $('#formSubmitHomeResponsive').trigger('submit');
    })

    // Check company when click on div
    $('.container-choice-company').click(function(e){
        e.preventDefault();
        var checkbox = $(this).children('input[type="checkbox"]');
        $(checkbox).prop('checked', !$(checkbox).prop('checked'));

        if($(this).children('input[type="checkbox"]').is(":checked")){
            $(this).children('input[type="checkbox"]').attr('checked','checked');
        }
        else if($(this).is(":not(:checked)")){
            $(this).children('input[type="checkbox"]').removeAttr('checked');
        }

        updateCompanyCheckboxs();
    });

    // Check if company selected
    var checkboxes = $('.container-choice-company input[type="checkbox"]');
    checkboxes.change(updateCompanyCheckboxs);

    $(document).on('keyup keypress', 'form input[type="text"]', function(e) {
        if(e.which == 13) {
          e.preventDefault();
          return false;
        }
    });

    //Non Responsive
    // Save filter after submit
    $('#contract_type').on('change', function() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
        $('#img-job-off-left').addClass('hiden');
        $('#img-job-on-left').removeClass('hiden');
        $('#img-job-on-result').removeClass('hiden');
        $('#img-job-off-result').addClass('hiden');
        $('.job-filter-selected').empty();
        $('.job-filter-selected').text($(this).find(":checked").text() + ", " + $('#job_type').find(":checked").text() );
        $('.yes-data-job').removeClass('hiden');
        $('.no-data-job').addClass('hiden');
        $('.result-lab-contract').removeClass('hiden');
        $('.filter-save-contract-type').text($(this).find(":checked").text());
        $('.result-job').removeClass('hiden');
        $('.result-contract').removeClass('hiden');
    });

    $('#job_type').on('change', function() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
        $('#img-job-off-left').addClass('hiden');
        $('#img-job-on-left').removeClass('hiden');
        $('.job-filter-selected').empty();
        // $('.job-filter-selected').text($('#contract_type').find(":checked").text() + ", " + $(this).find(":checked").text() );
        $('.job-filter-selected').text($(this).find(":checked").text() );
        $('.yes-data-job').removeClass('hiden');
        $('.no-data-job').addClass('hiden');
        $('.result-lab-job').removeClass('hiden');
        $('.filter-save-job-type').text($(this).find(":checked").text());
        $('.result-job').removeClass('hiden');
        $('.result-contract').removeClass('hiden');
    });

    //check company selected on responsive
    $('#company').on('change', function() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
    });

    // Check if lang selected
    var checkboxesLang = $('.check-lang-div input[type="checkbox"]');
    checkboxesLang.change( function() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
    });

    //Responsive
    // Save filter after submit
    $('#contract_type-responsive').on('change', function() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
    });

    $('#job_type-responsive').on('change', function() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
    });

    //check company selected on responsive
    $('#company-responsive').on('change', function() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
    });

    // Check if lang selected
    var checkboxesLang = $('.check-lang-div-responsive input[type="checkbox"]');
    checkboxesLang.change( function() {
        if (window.matchMedia('(max-width: 768px)').matches) {
            updateJobCountWatchDogResponsive.reset();
        } else {
            updateJobCountWatchDog.reset();
        }
    });

    /**
     * Do this to avoid an unwilling click on the Google Map just under #input-dis Dropdown menu.
     */
    $('#input-dis').on('select2:close', function (e) {
        radiusDropDownIsOpenWatchDog = Date.now() + 100;
    });
    filterTabInitialization();
    resetTabInitilization();
    initFuzzySearchCompanyListFilter();
    initGoogleMap();

    setInterval(function() {
        updateJobCountWatchDog.check(),
        updateJobCountWatchDogResponsive.check()
    }, 200);
}