import * as jobFormCommon from 'jobFormCommon';
export { init, fUJ }

var filesToUpload = [];

window.getCookie = function(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}
var currentLang = getCookie("language");
currentLang = currentLang.substring(0, 2);

const DESC_MAX_LENGTH = 8000;

var jobDescriptionFr = 0;
var jobDescriptionNl = 0;
var jobDescriptionEn = 0;

var idJobCategory = null;

var fUJ = {
    watchDogCompanyTime: 0,
    CompanyJustModified: false,

    watchDogNameTime: 0,
    nameJustModified: false,

    watchDogZipCodeTime: 0,
    zipCodeJustModified: false,

    attachmentStates: {},
}

var Upload = function (file, descriptionJobIdRow, langName, langId, progressBarId) {
    this.file = file;
    this.descriptionJobIdRow = descriptionJobIdRow;
    this.langName = langName;
    this.langId = langId;
    this.progressBarId = progressBarId;
};

/*
Upload.prototype.getSize = function() {
    return this.file.size;
};
*/
Upload.prototype.getName = function() {
    return this.file.name;
};

Upload.prototype.getDescriptionJobIdRow = function() {
    return this.descriptionJobIdRow;
};

Upload.prototype.doUpload = function () {
    var that = this;
    var formData = new FormData();

    // add assoc key values, this will be posts values
    formData.append("attachment-" + this.langName, this.file, this.getName());
    formData.append("upload_file", true);
    formData.append("lang_name", this.langName);
    formData.append("lang_id", this.langId);
    formData.append("description_job_id", this.descriptionJobIdRow);

    $.ajax({
        type: "POST",
        url: "/job/add/attachment",
        data: formData,
        xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
                myXhr.upload.addEventListener('progress', that.progressHandling.bind(null, that.progressBarId, that.lang), false);
            }
            return myXhr;
        },
        success: function (data) {
            try {
                var parsedData = JSON.parse(data);
            } catch (error) {
                //console.debug(data);
                console.error('Ajax answer malformed. Could not parse');
                return;
            }
            if (parsedData.error) {
                console.error('a problem occured: ', parsedData.error_code);
                return
            }

            filesToUpload.pop();
            if (filesToUpload.length == 0) {
                // No more file to upload, we can go back to job/lst
                if (idJobCategory.value === '1') {
                    window.location.href = "/job/list?lang=" + currentLang;
                } else {
                    window.location.href = "/internship/list?lang=" + currentLang;
                }
            }
        },
        error: function (error) {
            // handle error
        },
        cache: false,
        processData: false,
        contentType: false,
        timeout: 60000,
        async: true,
    });
};

Upload.prototype.progressHandling = function (progressBarId, idLang, event) {
    var percent = 0;
    var position = event.loaded || event.position;
    var total = event.total;
    if (event.lengthComputable) {
        percent = Math.ceil(position / total * 100);
    }

    $('#' + progressBarId).attr('aria-valuenow', percent);
    $('#' + progressBarId).css({'width': percent + '%'});
    $('#' + progressBarId).html(percent + '%');
};


function onSuccessJobModification(data) {
    // Times to upload any attachments files if there were added
    try {
        var data = JSON.parse(data).data;
    } catch (error) {
        console.error('Ajax answer malformed. Could not parse');
    }

    if ($('#attachment-fr')[0].files && $('#attachment-fr')[0].files.length) {
        // There is a french attachement that must be uploaded
        filesToUpload.push('file');
        $('#progress-attachement-fr-container').removeClass('hidden');
        var file = $('#attachment-fr')[0].files[0];
        var currentDescriptionJobId = data['fr'];
        var currentLangName = 'fr';
        var currentLangId = 1;
        var currentProgressBarId = 'progress-attachement-fr';
        var upload = new Upload(file, currentDescriptionJobId, currentLangName, currentLangId, currentProgressBarId);
        upload.doUpload();
    }

    if ($('#attachment-nl')[0].files && $('#attachment-nl')[0].files.length) {
        // There is a french attachement that must be uploaded
        filesToUpload.push('file');
        $('#progress-attachement-nl-container').removeClass('hidden');
        var file = $('#attachment-nl')[0].files[0];
        var currentDescriptionJobId = data['nl'];
        var currentLangName = 'nl';
        var currentLangId = 2;
        var currentProgressBarId = 'progress-attachement-nl';
        var upload = new Upload(file, currentDescriptionJobId, currentLangName, currentLangId, currentProgressBarId);
        upload.doUpload();
    }

    if ($('#attachment-en')[0].files && $('#attachment-en')[0].files.length) {
        // There is a french attachement that must be uploaded
        filesToUpload.push('file');
        $('#progress-attachement-en-container').removeClass('hidden');
        var file = $('#attachment-en')[0].files[0];
        var currentDescriptionJobId = data['en'];
        var currentLangName = 'en';
        var currentLangId = 3;
        var currentProgressBarId = 'progress-attachement-en';
        var upload = new Upload(file, currentDescriptionJobId, currentLangName, currentLangId, currentProgressBarId);
        upload.doUpload();
    }
}

function registerLatAndLong(event) {
    event.preventDefault();
    var city = $('#cities').children('option:selected').text();
    var zip = $('#zip').val();
    var province = $('#province').val();
    var addressin
    if (city.length != 0 || zip.length != 0 || province.length != 0) {
        addressin = city + ' ' + zip + ' ' + province;
    }
    else {
        var region = $('#region').val();
        var foreigncity = $('#foreign-city').val();
        var country = $('#country').children('option:selected').text();
        addressin = country + ' ' + region + ' ' + foreigncity ;
    }
    var request = {
        address : addressin
    }
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode(request, function(results, status) {
        if (google.maps.GeocoderStatus.OK == status) {
            var pos = results[0].geometry.location;
            $('#lat').val(pos.lat());
            $('#lng').val(pos.lng());
            onFormSubmit();
        } else {
            alert('Geocode was not successful for the following reason: ' + status);
        }
    });
}

function showAlertDescLength(length) {
    if (currentLang === 'fr') {
        return "SOUMETTRE : " + length + " caractères maximum autorisés.";
    }
    if (currentLang === 'nl') {
        return "VERZENDEN: Maximaal " + length + " tekens toegestaan.";
    }
    if (currentLang === 'en') {
        return "SUBMIT: Maximum " + length + " characters allowed.";
    }
}

function showAlertDescRequired() {
    if (currentLang === 'fr') {
        return "SOUMETTRE : L'annonce doit obligatoirement avoir une description";
    }
    if (currentLang === 'nl') {
        return "VERZENDEN: De advertentie moet een beschrijving hebben";
    }
    if (currentLang === 'en') {
        return "SUBMIT: The ad must have a description";
    }
}

function onFormSubmit(event) {
    // showAlertDescRequired();

    jobDescriptionFr = $("#job_description-fr").val().length;
    jobDescriptionNl = $("#job_description-nl").val().length;
    jobDescriptionEn = $("#job_description-en").val().length;

    if (
        ($('#inlineCheckbox1').is(':checked') && jobDescriptionFr === 0) ||
        ($('#inlineCheckbox2').is(':checked') && jobDescriptionNl === 0) ||
        ($('#inlineCheckbox3').is(':checked') && jobDescriptionEn === 0)
    ) {
        var message = showAlertDescRequired();
        alert(message);
        return;        
    }

    if (jobDescriptionFr >= DESC_MAX_LENGTH) {
        var message = showAlertDescLength(DESC_MAX_LENGTH);
        alert(message);
        return;
    }

    if (jobDescriptionNl >= DESC_MAX_LENGTH) {
        var message = showAlertDescLength(DESC_MAX_LENGTH);
        alert(message);
        return;
    }

    if (jobDescriptionEn >= DESC_MAX_LENGTH) {
        var message = showAlertDescLength(DESC_MAX_LENGTH);
        alert(message);
        return;
    }
    
    if ($('#actionSubmit').val() == "publish") {
        $('.error-msg').addClass('hidden');
        if (!jobFormCommon.validateAttachments()) {
            return;
        }

        var data = {};

        /**
         * Check that flatpickr Start and End Date are presents
         */
        var missingDateError = false;
        if ($('#flatpickr input').val() == '') {
            $('#starDateRequiredError').removeClass('hidden');
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#starDateRequiredError").offset().top - 50
            }, 200);
            missingDateError = true;
        }

        if ($('#flatpickr-end input').val() == '') {
            $('#endDateRequiredError').removeClass('hidden');
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#endDateRequiredError").offset().top - 50
            }, 200);
            missingDateError = true;
        }

        if (missingDateError) {
            return;
        }

        $('#flatpickr input').on('change', function() {
            $('#starDateRequiredError').addClass('hidden');
        })

        $('#formUpdJob').serializeArray().map(function(item) {
            if ( data[item.name] ) {
                if ( typeof(data[item.name]) === "string" ) {
                    data[item.name] = [data[item.name]];
                }
                data[item.name].push(item.value);
            } else {
                data[item.name] = item.value;
            }
        });

        if ($('#attachment-fr')[0].files && $('#attachment-fr')[0].files.length) {
            fUJ.attachmentStates['fr'].state = "has_file_to_upload";
            data.hasFrenchDescriptionToUpload = true;
        }
        if ($('#attachment-nl')[0].files && $('#attachment-nl')[0].files.length) {
            fUJ.attachmentStates['nl'].state = "has_file_to_upload";
            data.hasDutchDescriptionToUpload = true;
        }
        if ($('#attachment-en')[0].files && $('#attachment-en')[0].files.length) {
            fUJ.attachmentStates['en'].state = "has_file_to_upload";
            data.hasEnglishDescriptionToUpload = true;
        }
        var checkedFormID = $('#belgiumOrForeignRadioGrp input:checked').attr('id');
        if (checkedFormID === "belgiumRadio") {
            data['id_country'] = 11;
            data['region'] = '';
        }
        data.pageState = fUJ.attachmentStates;

        // Date format from flatpicker must be corrected.
        $.ajax({
            type: "POST",
            url: window.location.pathname,
            data: data,
            success: function (data) {
                try {
                    var parsedData = JSON.parse(data);
                } catch (error) {
                    //console.debug(data);
                    console.error('Ajax answer malformed. Could not parse');
                    return;
                }
                if (typeof parsedData.error === "undefined") {
                    console.error('Ajax answer malformed. error value undefined');
                    return
                }
                if (parsedData.error) {
                    console.error('An error occured during job creation.');
                    return
                }
                var frenchCondition = $('#attachment-fr')[0].files && $('#attachment-fr')[0].files.length;
                var dutchCondition = $('#attachment-nl')[0].files && $('#attachment-nl')[0].files.length;
                var englishCondition = $('#attachment-en')[0].files && $('#attachment-en')[0].files.length;

                if (frenchCondition || dutchCondition || englishCondition) {
                    onSuccessJobModification(data);
                } else {
                    // No file to upload
                    if (idJobCategory.value === '1') {
                        window.location.href = "/job/list?lang=" + currentLang;
                    } else {
                        window.location.href = "/internship/list?lang=" + currentLang;
                    }
                }
            },
            error: function (error) {
                // handle error
            },
        });
    }
    if ($('#actionSubmit').val() == "update") {
        $('#company').removeAttr('required');
        //$('#contract_type').removeAttr('required');
        //$('#job_type').removeAttr('required');
        //$('#function_type').removeAttr('required');
        $('#starDateRequiredError').removeAttr('required');
        $('#endDateRequiredError').removeAttr('required');
        $('#tel_contact').removeAttr('required');
        $('#email_contact').removeAttr('required');
        $('#zip').removeAttr('required');
        $('#region').removeAttr('required');
        $('#foreign-city').removeAttr('required');
        $('#title_function-fr').removeAttr('required');
        $('#title_function-nl').removeAttr('required');
        $('#title_function-en').removeAttr('required');

        if (!jobFormCommon.validateAttachments()) {
            return;
        }

        var data = {};

        if ($("form :invalid").length > 0) {
            if ($('#contract_type').val() == "") {
                $('.msg-contract').removeClass('hidden');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".msg-contract").offset().top - 50
                }, 200);
            } else {
                $('.msg-contract').addClass('hidden');
            }
            if ($('#job_type').val() == "") {
                $('.msg-type').removeClass('hidden');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".msg-type").offset().top - 50
                }, 200);
            } else {
                $('.msg-type').addClass('hidden');
            }
            if ($('#function_type').val() == "") {
                $('.msg-function').removeClass('hidden');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".msg-function").offset().top - 50
                }, 200);
            } else {
                $('.msg-function').addClass('hidden');
            }
            return;
        }

        $('#formUpdJob').serializeArray().map(function(item) {
            if ( data[item.name] ) {
                if ( typeof(data[item.name]) === "string" ) {
                    data[item.name] = [data[item.name]];
                }
                data[item.name].push(item.value);
            } else {
                data[item.name] = item.value;
            }
        });

        if ($('#attachment-fr')[0].files && $('#attachment-fr')[0].files.length) {
            fUJ.attachmentStates['fr'].state = "has_file_to_upload";
            data.hasFrenchDescriptionToUpload = true;
        }
        if ($('#attachment-nl')[0].files && $('#attachment-nl')[0].files.length) {
            fUJ.attachmentStates['nl'].state = "has_file_to_upload";
            data.hasDutchDescriptionToUpload = true;
        }
        if ($('#attachment-en')[0].files && $('#attachment-en')[0].files.length) {
            fUJ.attachmentStates['en'].state = "has_file_to_upload";
            data.hasEnglishDescriptionToUpload = true;
        }
        var checkedFormID = $('#belgiumOrForeignRadioGrp input:checked').attr('id');
        if (checkedFormID === "belgiumRadio") {
            data['id_country'] = 11;
            data['region'] = '';
        }
        data.pageState = fUJ.attachmentStates;

        var subStr = window.location.pathname.match("edit/(.*)");
        // Date format from flatpicker must be corrected.
        $.ajax({
            type: "POST",
            url: "/job/updregister/" + subStr[1],
            data: data,
            success: function (data) {
                try {
                    var parsedData = JSON.parse(data);
                } catch (error) {
                    //console.debug(data);
                    console.error('Ajax answer malformed. Could not parse');
                    return;
                }
                if (typeof parsedData.error === "undefined") {
                    console.error('Ajax answer malformed. error value undefined');
                    return
                }
                if (parsedData.error) {
                    console.error('An error occured during job creation.');
                    return
                }
                var frenchCondition = $('#attachment-fr')[0].files && $('#attachment-fr')[0].files.length;
                var dutchCondition = $('#attachment-nl')[0].files && $('#attachment-nl')[0].files.length;
                var englishCondition = $('#attachment-en')[0].files && $('#attachment-en')[0].files.length;

                if (frenchCondition || dutchCondition || englishCondition) {
                    onSuccessJobModification(data);
                } else {
                    // No file to upload
                    if (idJobCategory.value === '1') {
                        window.location.href = "/job/list?lang=" + currentLang;
                    } else {
                        window.location.href = "/internship/list?lang=" + currentLang;
                    }
                }
            },
            error: function (error) {
                // handle error
            },
        });
    }
}



function initZipCodeIfBelgiumWasSelected() {
    var zipCodeVal = $('#formUpdJob #zip').val();
    if (!zipCodeVal) {
        return;
    }

    /**
     * In this context, we know that user choosed Belgium
     */

    // Check Lang is supported, 1: french, 2: dutch.
    var idLang = $('#current-lang').attr('data-lang');
    if (typeof idLang === "undefined" || ['1','2'].indexOf(idLang) == -1) {
        idLang = '1'; // Default
    }

    var data = {
        zipcode: zipCodeVal,
        id_lang: idLang
    }

    $.ajax({
        method: "POST",
        url: "/zipcode",
        data: data
    })
        .done(function( data ) {
            try {
                var data = JSON.parse(data);
            } catch (error) {
                console.error('Ajax answer malformed. Could not parse');
            }

            if (data.error) {
                switch (data.error_code) {
                    case "SQL_ERROR":
                        console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                        break;
                    case "NO_ZIP_CODE_FOUND":
                        // Nothing to do
                        break;
                }
                return;
            }

            $("#cities").empty();
            for(var i=0;i<data.data.length;i++)
            {
                var city = data.data[i].cityName.replace(/\'/g,'&apos;');
                $("#cities").append("<option value='" + 
                city + "'>" + city + "</option>");
                $('#province').val(data.data[i].provinceName);
            }

            var originalCity = $('#cities').attr('data-original-value');
            if (typeof originalCity !== "undefined" && originalCity != '') {
                $('#cities option:contains("' + originalCity + '")').prop('selected', true);
            }
        });
}

function calcHeight(value) {
    let numberOfLineBreaks = (value.match(/\n/g) || []).length;
    // min-height + lines x line-height + padding + border
    let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
    return newHeight;
  }

  function getPageState() {
    var frDataElement = $('#page-data-state .attachment-fr');
    var nlDataElement = $('#page-data-state .attachment-nl');
    var enDataElement = $('#page-data-state .attachment-en');


    fUJ.attachmentStates['fr'] ={
        'originalFileName': frDataElement.attr('data-original-filename'),
        'fileName': frDataElement.attr('data-filename'),
        'jobId': frDataElement.attr('data-job-id'),
        'descriptionJobId': frDataElement.attr('data-description-job-id'),
    }

    if (fUJ.attachmentStates['fr'].descriptionJobId == '') {
        fUJ.attachmentStates['fr'].state = "description_not_created_yet";
    } else {
        if (fUJ.attachmentStates['fr'].fileName == '') {
            fUJ.attachmentStates['fr'].state = "description_exists_has_not_attachment_yet";
        } else {
            fUJ.attachmentStates['fr'].state = "has_attachment";
        }
    }

    fUJ.attachmentStates['nl'] ={
        'originalFileName': nlDataElement.attr('data-original-filename'),
        'fileName': nlDataElement.attr('data-filename'),
        'jobId': nlDataElement.attr('data-job-id'),
        'dataDescriptionJobId': nlDataElement.attr('data-description-job-id'),
    }

    if (fUJ.attachmentStates['nl'].descriptionJobId == '') {
        fUJ.attachmentStates['nl'].state = "description_not_created_yet";
    } else {
        if (fUJ.attachmentStates['nl'].fileName == '') {
            fUJ.attachmentStates['nl'].state = "description_exists_has_not_attachment_yet";
        } else {
            fUJ.attachmentStates['nl'].state = "has_attachment";
        }
    }

    fUJ.attachmentStates['en'] ={
        'originalFileName': enDataElement.attr('data-original-filename'),
        'fileName': enDataElement.attr('data-filename'),
        'jobId': enDataElement.attr('data-job-id'),
        'dataDescriptionJobId': enDataElement.attr('data-description-job-id'),
    }

    if (fUJ.attachmentStates['en'].descriptionJobId == '') {
        fUJ.attachmentStates['en'].state = "description_not_created_yet";
    } else {
        if (fUJ.attachmentStates['en'].fileName == '') {
            fUJ.attachmentStates['en'].state = "description_exists_has_not_attachment_yet";
        } else {
            fUJ.attachmentStates['en'].state = "has_attachment";
        }
    }

    //console.log(fUJ.attachmentStates);
}

function init() {
    //Resize auto textarea
    //FR
    /****/
    let function_description = document.querySelector(".description_fr");
    if (function_description) {
        function_description.addEventListener("keyup", () => {
            //console.log('touche function_description-fr');
            function_description.style.height = calcHeight(function_description.value) + "px";
        });
    }

    let profil_description_fr = document.querySelector(".prof-description-fr");
    if (profil_description_fr) {
        profil_description_fr.addEventListener("keyup", () => {
            //console.log('profil_description-fr');
            profil_description_fr.style.height = calcHeight(profil_description_fr.value) + "px";
        });
    }

    let job_description_fr = document.querySelector(".job-description-fr");
    if (job_description_fr) {
        job_description_fr.addEventListener("keyup", () => {
            //console.log('job_description-fr');
            job_description_fr.style.height = calcHeight(job_description_fr.value) + "px";
        });
    }

    let job_description_add_fr = document.querySelector(".prof-description-add-fr");
    if (job_description_add_fr) {
        job_description_add_fr.addEventListener("keyup", () => {
            //console.log('job_description_add-fr');
            job_description_add_fr.style.height = calcHeight(job_description_add_fr.value) + "px";
        });
    }

    //NL
    /****/
    let function_description_nl = document.querySelector(".description_nl");
    if (function_description_nl) {
        function_description_nl.addEventListener("keyup", () => {
            //console.log('touche function_description-nl');
            function_description_nl.style.height = calcHeight(function_description_nl.value) + "px";
        });
    }

    let profil_description_nl = document.querySelector(".prof-description-nl");
    if (profil_description_nl) {
        profil_description_nl.addEventListener("keyup", () => {
            //console.log('profil_description-nl');
            profil_description_nl.style.height = calcHeight(profil_description_nl.value) + "px";
        });
    }

    let job_description_nl = document.querySelector(".job-description-nl");
    if (job_description_nl) {
        job_description_nl.addEventListener("keyup", () => {
            //console.log('job_description-nl');
            job_description_nl.style.height = calcHeight(job_description_nl.value) + "px";
        });
    }

    let job_description_add_nl = document.querySelector(".prof-description-add-nl");
    if (job_description_add_nl) {
        job_description_add_nl.addEventListener("keyup", () => {
            //console.log('job_description_add-nl');
            job_description_add_nl.style.height = calcHeight(job_description_add_nl.value) + "px";
        });
    }

    //EN
    /****/
    let function_description_en = document.querySelector(".description_en");
    if (function_description_en) {
        function_description_en.addEventListener("keyup", () => {
            //console.log('touche function_description-en');
            function_description_en.style.height = calcHeight(function_description_en.value) + "px";
        });
    }

    let profil_description_en = document.querySelector(".prof-description-en");
    if (profil_description_en) {
        profil_description_en.addEventListener("keyup", () => {
            //console.log('profil_description-en');
            profil_description_en.style.height = calcHeight(profil_description_en.value) + "px";
        });
    }

    let job_description_en = document.querySelector(".job-description-en");
    if (job_description_en) {
        job_description_en.addEventListener("keyup", () => {
            //console.log('job_description-en');
            job_description_en.style.height = calcHeight(job_description_en.value) + "px";
        });
    }

    let job_description_add_en = document.querySelector(".prof-description-add-en");
    if (job_description_add_en) {
        job_description_add_en.addEventListener("keyup", () => {
            //console.log('job_description_add-en');
            job_description_add_en.style.height = calcHeight(job_description_add_en.value) + "px";
        });
    }

    //TinyCME
    tinymce.init({
        selector: 'textarea',
        plugins: 'autoresize print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
        imagetools_cors_hosts: ['picsum.photos'],
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template anchor codesample | ltr rtl',
        font_formats: 'Arial=arial,sans-serif; Helvetica=helvetica; Symbol=symbol' ,
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        content_css: '//www.tiny.cloud/css/codepen.min.css',
        link_list: [
            { title: 'My page 1', value: 'http://www.tinymce.com' },
            { title: 'My page 2', value: 'http://www.moxiecode.com' }
        ],
        image_list: [
            { title: 'My page 1', value: 'http://www.tinymce.com' },
            { title: 'My page 2', value: 'http://www.moxiecode.com' }
        ],
        image_class_list: [
            { title: 'None', value: '' },
            { title: 'Some class', value: 'class-name' }
        ],
        importcss_append: true,
        file_picker_callback: function (callback, value, meta) {
            /* Provide file and text for the link dialog */
            if (meta.filetype === 'file') {
            //callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
            }

            /* Provide image and alt text for the image dialog */
            if (meta.filetype === 'image') {
            //callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
            }

            /* Provide alternative source and posted for the media dialog */
            if (meta.filetype === 'media') {
            callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
            }
        },
        templates: [
            { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
            { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
            { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
        ],
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        height: 600,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: 'wrap',
        contextmenu: "image imagetools table",
        branding: false,
        setup: function(editor) {
            // editor.on('init', function(e) {
            //   console.log('The Editor has initialized.');
            // });
            editor.on('keyup', function(e) {
                var content = e.currentTarget.innerHTML;
                var id = e.currentTarget.dataset.id;
                var length = getCount(content);
                // console.log(id);

                if (id === 'job_description-en') {
                    jobDescriptionEn = length;
                } else if (id === 'job_description-nl') {
                    jobDescriptionNl = length;
                } else {
                    jobDescriptionFr = length;
                }

                if (jobDescriptionFr >= DESC_MAX_LENGTH) {
                    var message = showAlertDescLength(DESC_MAX_LENGTH);
                    alert(message);
                    return;
                }
            
                if (jobDescriptionNl >= DESC_MAX_LENGTH) {
                    var message = showAlertDescLength(DESC_MAX_LENGTH);
                    alert(message);
                    return;
                }
            
                if (jobDescriptionEn >= DESC_MAX_LENGTH) {
                    var message = showAlertDescLength(DESC_MAX_LENGTH);
                    alert(message);
                    return;
                }
            });
        }
    });

    function getCount(tx) {
        // var tx = editor.getContent({ format: 'raw' });
        var decoded = decodeHtml(tx);
        // here we strip all HTML tags
        var decodedStripped = decoded.replace(/(<([^>]+)>)/ig, "").trim();
        var tc = decodedStripped.length;
        return tc;
      };

    function decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    getPageState();
    jobFormCommon.jobTabInitialization();
    jobFormCommon.initSelect2Config();
    jobFormCommon.initBelgiumOrForeignCountryContainer();

    $('#formUpdJob .submit-button-container button').click(function(e) {
        //e.preventDefault();
        if($(this).attr("value") == "update") {
            //alert("Register Button is pressed ")
            $('#actionSubmit').val("update");
            //$("#formUpdJob").submit();
        }
        if($(this).attr("value") == "publish") {
            //alert("publish Button is pressed ")
            $('#actionSubmit').val("publish");
            //$("#formUpdJob").submit();
        }

    });
    $('#formUpdJob').on('submit', registerLatAndLong);

    // Detect if name_contact has been changed
    $('#formUpdJob #name_contact').on('change', function() {
        fUJ.watchDogNameTime = Date.now() + 500;
        fUJ.nameJustModified = true;
    });

    // Detect if company has been changed
    $('#formUpdJob #company').on('change', function() {
        fUJ.watchDogCompanyTime = Date.now() + 500;
        fUJ.CompanyJustModified = true;
    });

    // Detect if zip has been changed
    $('#formUpdJob #zip').on('change', function() {
        if (!jobFormCommon.getItsBelgium()) {
            return;
        }
        fUJ.watchDogZipCodeTime = Date.now() + 500;
        fUJ.zipCodeJustModified = true;
    });


    // Handle name_contact Change in form
    setInterval(function() {
        if (fUJ.nameJustModified) {
            if (Date.now() < fUJ.watchDogNameTime) {
                return;
            }
            fUJ.nameJustModified = false;
        } else {
            return;
        }

        var contactname = $('#formUpdJob #name_contact').children('option:selected').val();
        if (typeof contactname === "undefined") {
            console.error('Name not choosen')
            return;
        }

        var id_company = $('#formUpdJob #company').children('option:selected').val();
        if (typeof id_company === "undefined") {
            console.error('id_company not choosen')
            return;
        }

        var data = {
            contactname: contactname,
            id_company: id_company
        }

        $.ajax({
            method: "POST",
            url: "/contactperson",
            data: data
          })
            .done(function( data ) {
                //console.log(data)
                try {
                    var data = JSON.parse(data);
                    ////console.log(data)
                } catch (error) {
                    console.error('Ajax answer malformed. Could not parse');
                }

                if (data.error) {
                    switch (data.error_code) {
                        case "SQL_ERROR":
                            console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                            break;
                        case "NO_CONTACT_INFO_FOUND":
                            // Nothing to do
                            break;
                    }
                    return;
                }
                $('#tel_contact').val(data.data.phone);
                $('#email_contact').val(data.data.mail);
            });

    }, 100)

    // Handle Company change in form
    setInterval(function() {
        if (fUJ.CompanyJustModified) {
            if (Date.now() < fUJ.watchDogCompanyTime) {
                return;
            }
            fUJ.CompanyJustModified = false;
        } else {
            return;
        }

        var id_company = $('#formUpdJob #company').children('option:selected').val();
        if (typeof id_company === "undefined") {
            console.error('id_company not choosen')
            return;
        }

        var data = {
            id_company: id_company
        }

        $.ajax({
            method: "POST",
            url: "/company",
            data: data
          })
            .done(function( data ) {
                try {
                    var data = JSON.parse(data);
                    ////console.log(data)
                } catch (error) {
                    console.error('Ajax answer malformed. Could not parse');
                }

                if (data.error) {
                    switch (data.error_code) {
                        case "SQL_ERROR":
                            console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                            break;
                        case "NO_ZIP_CODE_FOUND":
                            // Nothing to do
                            break;
                    }
                    return;
                }

                $("#name_contact").empty();
                var name1 = data.data.recruiting_manager_name1.replace(/\'/g,'&apos;');
                var mail1 = data.data.recruiting_manager_mail1.replace(/\'/g,'&apos;');
                var phone1 = data.data.recruiting_manager_phone1.replace(/\'/g,'&apos;');
                var name2 = data.data.recruiting_manager_name2.replace(/\'/g,'&apos;');
                var mail2 = data.data.recruiting_manager_mail2.replace(/\'/g,'&apos;');
                var phone2 = data.data.recruiting_manager_phone2.replace(/\'/g,'&apos;');
                $("#name_contact").append("<option value='" +
                    name1 + "'>" + name1 + "</option>");
                $("#name_contact").append("<option value='" +
                    name2 + "'>" + name2 + "</option>");

                $('#tel_contact').val(phone1);
                $('#email_contact').val(mail1);
            });

    }, 100)

    // Handle zipCode Change in form
    setInterval(function() {
        // Not Belgium ?  We don't care.
        if (!jobFormCommon.getItsBelgium()) {
            return;
        }

        if (fUJ.zipCodeJustModified) {
            if (Date.now() < fUJ.watchDogZipCodeTime) {
                return;
            }
            fUJ.zipCodeJustModified = false;
        } else {
            return;
        }

        var zipCodeVal = $('#formUpdJob #zip').val();
        if (!zipCodeVal) {
            return;
        }

        /**
         * In this context, we know that user choosed Belgium
         */

        // Check Lang is supported, 1: french, 2: dutch.
        var idLang = $('#current-lang').attr('data-lang');
        if (typeof idLang === "undefined" || ['1','2'].indexOf(idLang) == -1) {
            idLang = '1'; // Default
        }

        var data = {
            zipcode: zipCodeVal,
            id_lang: idLang
        }

        $.ajax({
            method: "POST",
            url: "/zipcode",
            data: data
        })
            .done(function( data ) {
                try {
                    var data = JSON.parse(data);
                } catch (error) {
                    console.error('Ajax answer malformed. Could not parse');
                }

                if (data.error) {
                    switch (data.error_code) {
                        case "SQL_ERROR":
                            console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                            break;
                        case "NO_ZIP_CODE_FOUND":
                            // Nothing to do
                            break;
                    }
                    return;
                }

                $("#cities").empty();
                for(var i=0;i<data.data.length;i++)
                {
                    var city = data.data[i].cityName.replace(/\'/g,'&apos;');
                    $("#cities").append("<option value='" + 
                    city + "'>" + city + "</option>");
                    $('#province').val(data.data[i].provinceName);
                }
            });

    }, 100)

    /**
     *  Init
     */
    $('#job_type').on('change', function() {
        var defaultFunctionTypeId = $('#job_type option:selected').attr('data-id-default-function-type');
        if (defaultFunctionTypeId) {
            $('#function_type').val(defaultFunctionTypeId);
            $('#function_type').trigger('change');
        }
    });

    /**
     * Delete Attachment
     */

    $('.btn-delete-attachment').on('click', function(event) {
        event.preventDefault();

        var url = $(this).attr('href');
        $.ajax({
            type: "POST",
            url: url,

            success: function (data) {
                $('.del-modal').modal('hide');
                $('.del-modal').addClass('hidden');
                var idLang = $(this)[0].url.split('/')[4];
                switch(idLang) {
                    case "1":
                        $('#upload-fr').removeClass('hidden');
                        $('#existing-attachment-fr').addClass('hidden');
                        fUJ.attachmentStates['fr'].state = "remove_attachment";
                        break;
                    case "2":
                        $('#upload-nl').removeClass('hidden');
                        $('#existing-attachment-nl').addClass('hidden');
                        fUJ.attachmentStates['nl'].state = "remove_attachment";
                        break;
                    case "3":
                        $('#upload-en').removeClass('hidden');
                        $('#existing-attachment-en').addClass('hidden');
                        fUJ.attachmentStates['en'].state = "remove_attachment";
                        break;
                }
            },
            error: function (error) {
                // handle error
                //console.debug('could not delete selected attachment');
            },
            cache: false,
            processData: false,
            contentType: false,
            timeout: 60000,
            async: true,
        });
     })

    idJobCategory = document.querySelector("#id_job_category");
    // console.log(typeof idJobCategory.value);

    $('.del-fr-attachment').on('click', function(event) {
        event.preventDefault();
        $('#modal-del-fr-attachment').modal('show');
        $('#modal-del-fr-attachment').removeClass('hidden');
    })

    $('.del-nl-attachment').on('click', function(event) {
        event.preventDefault();
        $('#modal-del-nl-attachment').modal('show');
        $('#modal-del-nl-attachment').removeClass('hidden');
    })

    $('.del-en-attachment').on('click', function(event) {
        event.preventDefault();
        $('#modal-del-en-attachment').modal('show');
        $('#modal-del-en-attachment').removeClass('hidden');
    })

    initZipCodeIfBelgiumWasSelected();
}