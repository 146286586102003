// var $  = require( 'jquery' );
// var dt = require( 'datatables.net' )();

export {init};

function init() {
        //*********/
        // COMPANY*/
        //*********/
        var attachment_cv = null;
        var attachment_optionnal = null;
        $('a[data-toggle=modal]').click(function () {

            var data_jobapplicationid= $(this).data('jobapplicationid') || '';
            var data_jobformtype = $(this).data('jobformtype') == 'CSS' ? 'Candidature spontanée Stage' :
                                   $(this).data('jobformtype') == 'CSE' ? 'Candidature spontanée Emploi' :
                                   $(this).data('jobformtype') == 'OE' ? 'Offre d\'Emploi' :
                                   $(this).data('jobformtype') == 'OS' ? 'Offre de stage' : '';
            var data_jobid = $(this).data('jobid') || '';
            var data_companyid = $(this).data('companyid') || '';
            var data_jobtitle = $(this).data('jobtitle') || '';
            var data_firstname = $(this).data('firstname') || '';
            var data_lastname = $(this).data('lastname') || '';
            var data_motivation = $(this).data('motivation') || '';
            var data_newsletter = $(this).data('newsletter') || '';
            var data_email = $(this).data('email') || '';
            var data_phone = $(this).data('phone') || '';
            var data_createdat = $(this).data('createdat') || '';
            var data_updatedat = $(this).data('updatedat') || '';
            var data_cv = $(this).data('cv') || '';
            var optionnalfile = $(this).data('optionnalfile') || null;

            attachment_cv = data_cv;
            attachment_optionnal = optionnalfile;

            if (!attachment_optionnal) {
                $('#downloadOptionnal').css('color', '#e94e1b');
            } else {
                $('#downloadOptionnal').css('color', '#009fe3');
            }
            // //console.log(data_firstname + ' ' + data_lastname);

            $('#view-details-jobapplication').on('show.bs.modal', function(e) {
                // //console.log(data_firstname + ' ' + data_lastname);
                //debugger
                $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));

                $('#firstname').text(data_firstname);
                $('#lastname').text(data_lastname);
                $('#phone').text(data_phone);
                $('#email').text(data_email);

                $('#formType').text(data_jobformtype);
                // $('#jobName').text(data_jobid);
                $('#jobName').text(data_jobtitle);
                $('#companyName').text(data_companyid);
                $('#motivation').text(data_motivation);

                var newsletter = data_newsletter === 1 ? '<i class="fas fa-check text-success"></i>' : '<i class="fas fa-times text-danger"></i>';
                $('#newsletter').html(newsletter);

                $('#createdAt').text(data_createdat);
                $('#updatedAt').text(data_updatedat);
            });
    });

    $('#downloadCV').click(function(event) {
        event.preventDefault();
        //console.log('downloadCV');
        downloadFile(attachment_cv);
    });

    $('#downloadOptionnal').click(function(event) {
        event.preventDefault();
        //console.log('downloadOptionnal');
        downloadFile(attachment_optionnal);
    });

    $('#downloadCSE_CSVFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = $(this).data('formtype');
        // //console.log('downloadCSE_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'csv');
    });

    $('#downloadCSS_CSVFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = $(this).data('formtype');
        // //console.log('downloadCSS_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'csv');
    });

    $('#downloadOE_CSVFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = $(this).data('formtype');
        // //console.log('downloadOE_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'csv');
    });

    $('#downloadOS_CSVFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = $(this).data('formtype');
        // //console.log('downloadOS_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'csv');
    });

    $('#downloadCSE_XLSXFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = $(this).data('formtype');
        // //console.log('downloadCSE_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'xlsx');
    });

    $('#downloadCSS_XLSXFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = $(this).data('formtype');
        // //console.log('downloadCSS_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'xlsx');
    });

    $('#downloadOE_XLSXFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = $(this).data('formtype');
        // //console.log('downloadOE_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'xlsx');
    });

    $('#downloadOS_XLSXFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = $(this).data('formtype');
        // //console.log('downloadOS_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'xlsx');
    });

    $('#downloadAll_CSVFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = 'All';
        // //console.log('downloadOS_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'csv');
    });

    $('#downloadAll_XLSXFile').click(function(event) {
        event.preventDefault();
        var ids_compagnies = $(this).data('compagnies');
        var formType = 'All';
        // //console.log('downloadOS_CSVFile', ids_compagnies, formType);
        downloadCSV(ids_compagnies, formType, 'xlsx');
    });

    // let table = new DataTable('#list_cd_applications', {
    //     // options
    // });
    var currentLang = getCookie("language");
    //console.log(currentLang);

    var language = {};

    if (currentLang === 'fr_BE') {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/French.json"
        }
    } else if (currentLang === 'nl_BE') {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/Dutch.json"
        }
    } else {
        language = {
            "url": "//cdn.datatables.net/plug-ins/1.10.21/i18n/English.json"
        }
    }
    
    // DataTable.moment('DD/MM/YY');
    var dtOptions = {
        language: language,
        ordering: true,
        order: [[ 7, "desc" ]], //or asc 
        columnDefs : [{targets: 7, type: "date-uk"}],
    };

    $('#list_cd_applications').DataTable(dtOptions);
    $('#list_cs_applications').DataTable(dtOptions);
}

function downloadCSV(ids_compagnies, formType, fileType) {
    
    return csvFileProcess(ids_compagnies, formType, fileType);
}

function csvFileProcess(ids_compagnies, formType, fileType) {
    
    // //console.log('downloadCSE_CSVFile click works !!', ids_compagnies, formType);
    
    var formData = new FormData();

    formData.append('ids_compagnies', ids_compagnies);
    formData.append('formtype', formType);
    formData.append('filetype', fileType);

    $.ajax({
        type: "POST",
        url: "/imp-exp/export-applications",
        data: formData,
        success: function (data) {
            //console.log(data);
            try {
                var parsedData = JSON.parse(data);
            } catch (error) {
                console.debug(data);
                console.error('Ajax answer malformed. Could not parse');
                return;
            }

            if (typeof parsedData.error !== 'undefined' && parsedData.error) {
                switch (parsedData.error_code) {
                    case "DATA_NOT_FOUND":
                        $('#export-no-data-found-alert').removeClass('hidden');
                        break;
                    case "LST_COMPANY_NOT_PRESENT":
                    case "LST_COMPANY_IS_NOT_AN_ARRAY":
                    case "LST_COMPANY_MUST_HAVE_AT_LEAST_ONE_ELEMENT":
                    case "LST_COMPANY_EVERY_ELEMENT_MUST_BE_INT":
                    case "CANNOT_CREATE_CSV_FILE":
                    default:
                        $('#export-default-error-alert').removeClass('hidden');
                        console.error('a problem occured: ', parsedData.error_code);
                        break;
                }
                return
            }

            window.location.replace('/imp-exp/download-export/' + fileType + '/' + parsedData.data);
        },

        error: function (error) {
            // handle error
        },
        cache: false,
        processData: false,
        contentType: false,
        timeout: 60000,
        async: true,
    });
}

function downloadFile(fileName) {
    //console.log(fileName);
    if (fileName) {
        var fileType = fileName.split('.')[1];
        window.location.replace('/imp-exp/download-attachment/' + fileType + '/' + fileName);
    }
}
