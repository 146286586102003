import * as select2Config from 'select2Config';
export {init};

var fUC = {
    watchDogZipCodeTime: 0,
    zipCodeJustModified: false,
    watchDogCountryCodeTime : 0,
    CountryJustModified : false,
    watchDogVatNumberTime : 0,
    VatNumberJustModified : false
}

var forms = document.getElementsByClassName('needs-validation');
var validation = Array.prototype.filter.call(forms, function(form) {
    form.addEventListener('submit', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      } else {
          //Check if company vat exist 
        if ( $('#company_vat').hasClass("is-invalid")) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            form.classList.add('was-validated');
        }
      }
      form.classList.add('was-validated');
    }, false);
  });


function init() {
    var select2BaseConfig = select2Config.getSelect2BaseConfig();

    $('#citys').select2(select2BaseConfig);
    $('#country').select2(select2BaseConfig);

    $('#formUpdCompany #postal_code').on('input', function() {
        fUC.watchDogZipCodeTime = Date.now() + 500;
        fUC.zipCodeJustModified = true;
    });

    $('#formUpdCompany #country').on('change', function() {
        //debugger;
        fUC.watchDogCountryCodeTime = Date.now() + 500;
        fUC.CountryJustModified = true;
    });

    $('#formUpdCompany #company_vat').on('input', function() {
        //debugger;
        fUC.watchDogVatNumberTime = Date.now() + 500;
        fUC.VatNumberJustModified = true;
    });

    // Handle Zipcode Change in form
    setInterval(function() {
        // //console.log(fUC.watchDogZipCodeTime, fUC.zipCodeJustModified, fUC.watchDogZipCodeTime - Date.now());
        if (fUC.zipCodeJustModified) {
            if (Date.now() < fUC.watchDogZipCodeTime) {
                return;
            }
            fUC.zipCodeJustModified = false;
        } else {
            return;
        }

        var zipCodeVal = $('#formUpdCompany #postal_code').val();
        if (!zipCodeVal) {
            return;
        }
        var idCountry = $('#formUpdCompany #country').children('option:selected').val();
        // idCountry = 11 --> Belgium. We don't check any other zipcode.
        if (typeof idCountry === "undefined") {
            console.error('country not choosen')
            return;
        }
        if (idCountry != 11) {
            //console.debug("We don't check, it's not belgium")
            return;
        }

        // Check Lang is supported, 1: french, 2: dutch.
        var idLang = $('#current-lang').attr('data-lang');
        if (typeof idLang === "undefined" || ['1','2'].indexOf(idLang) == -1) {
            idLang = '1'; // Default
        }

        var data = {
            zipcode: zipCodeVal,
            id_lang: idLang
        }

        $.ajax({
            method: "POST",
            url: "/zipcode",
            data: data
          })
            .done(function( data ) {
                //console.log(data);
                try {
                    var data = JSON.parse(data);
                } catch (error) {
                    console.error('Ajax answer malformed. Could not parse');
                }

                if (data.error) {
                    switch (data.error_code) {
                        case "SQL_ERROR":
                            console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                            break;
                        case "NO_ZIP_CODE_FOUND":
                            // Nothing to do
                            break;
                    }
                    return;
                }

                $("#cities").empty();
                for(var i=0;i<data.data.length;i++)
                {
                    //debugger;
                    var city = data.data[i].cityName.replace(/\'/g,'&apos;');
                    $("#cities").append("<option value='" + 
                    city + "'>" + city + "</option>");
                    $('#province').val(data.data[i].provinceName);
                }
                //$('datalist option:first-child').attr("selected", "selected");
                //$('#province').val(data.data[1].provinceName);
            });

    }, 100)

    // Handle Country Change in form
    setInterval(function() {
        if (fUC.CountryJustModified) {
            if (Date.now() < fUC.watchDogCountryCodeTime) {
                return;
            }
            fUC.CountryJustModified = false;
        } else {
            return;
        }

        var idCountry = $('#formUpdCompany #country').children('option:selected').val();
        if (typeof idCountry === "undefined") {
            console.error('country not choosen')
            return;
        }

        var data = {
            id_country: idCountry,
        }

        $.ajax({
            method: "POST",
            url: "/country",
            data: data
          })
            .done(function( data ) {
                //console.log(data)
                try {
                    var data = JSON.parse(data);
                    ////console.log(data)
                } catch (error) {
                    console.error('Ajax answer malformed. Could not parse');
                }

                if (data.error) {
                    switch (data.error_code) {
                        case "SQL_ERROR":
                            console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                            break;
                        case "NO_ZIP_CODE_FOUND":
                            // Nothing to do
                            break;
                    }
                    return;
                }
                $('#country-vat').val(data.data.codeTva);
                $('#country-tel').val(data.data.codeTel);
            });

    }, 100)

     // Handle Vat number Change in form
     setInterval(function() {
        if (fUC.VatNumberJustModified) {
            if (Date.now() < fUC.watchDogVatNumberTime) {
                return;
            }
            fUC.VatNumberJustModified = false;
        } else {
            return;
        }

        var vatnumber = $('#formUpdCompany #company_vat').val();
        //console.log(vatnumber)
        if (!vatnumber) {
            return;
        }

        var idcompany = $('#formUpdCompany #company_vat').attr('data-id');
        if (!idcompany) {
            return;
        }

        var data = {
            vatnumber: vatnumber,
            idcompany: idcompany
        }

        $.ajax({
            method: "POST",
            url: "/checkvat",
            data: data
          })
            .done(function( data ) {
                //debugger;
                try {
                    var data = JSON.parse(data);
                    ////console.log(data)
                } catch (error) {
                    console.error('Ajax answer malformed. Could not parse' + error );
                }

                if (data.error) {
                    switch (data.error_code) {
                        case "SQL_ERROR":
                            console.error('Something were wrong when attempting to get some information about the zipcode you entered, contact your administrator')
                            break;
                        case "NO_ZIP_CODE_FOUND":
                            // Nothing to do
                            break;
                    }
                    return;
                }
                // debugger;
                if (data.data.vatcheck == 'find') {
                    $('#company_vat').css("border-color", "#dc3545");
                    $('#company_vat').addClass('is-invalid');
                                     //.addClass(this.checkValidity() ? 'is-valid' : 'is-invalid');
                    $('#invalide-vat').css("display","block");
                } else {
                    $('#company_vat').css("border-color", "#28a745");
                    $('#company_vat').removeClass('is-invalid');
                                     //.addClass(this.checkValidity() ? 'is-valid' : 'is-invalid');
                    $('#invalide-vat').css("display","none");
                }
                //$('#country-vat').val(data.data.codeTva);
                //$('#country-tel').val(data.data.codeTel);
            });

    }, 100)
}