import * as select2Config from 'select2Config';

export { jobTabInitialization, initSelect2Config, initBelgiumOrForeignCountryContainer, getItsBelgium, validateAttachments }

var frenchChecked = false;
var dutchChecked = false;
var englishChecked = false;

var itsBelgium = undefined;

function hasExtension(fileName, exts) {

    var extensionValid = false;
    for (var j = 0; j < exts.length; j++) {
        var currentExtension = exts[j];
        if (fileName.substr(fileName.length - currentExtension.length, currentExtension.length).toLowerCase() == currentExtension.toLowerCase()) {
            extensionValid = true;
            break;
        }
    }

    if (!extensionValid) {
        return false;
    }

    return true;
}

/**
 * Check Attachments have the right extensions and aren't too big.
 */
function validateAttachments() {
    var valid = true;
    // French Attachments
    if (typeof $('#attachment-fr')[0].files[0] !== "undefined") {
        if (!hasExtension($('#attachment-fr')[0].files[0].name, ['txt', 'jpg', 'gif', 'png', 'jpeg', 'pdf', 'doc', 'docx'])) {
            $('.fr-file-bad-extension').removeClass('hidden');
            $('#tab1addjob').trigger('click');
            valid = false;
        }
        if ($('#attachment-fr')[0].files[0].size > 31457280) {
            $('.fr-file-too-big').removeClass('hidden');
            $('#tab1addjob').trigger('click');
            valid = false;
        }
    }

    // Dutch Attachments
    if (typeof $('#attachment-nl')[0].files[0] !== "undefined") {
        if (!hasExtension($('#attachment-nl')[0].files[0].name, ['txt', 'jpg', 'gif', 'png', 'jpeg', 'pdf', 'doc', 'docx'])) {
            $('.nl-file-bad-extension').removeClass('hidden');
            $('#tab2addjob').trigger('click');
            valid = false;
        }
        if ($('#attachment-nl')[0].files[0].size > 31457280) {
            $('.nl-file-too-big').removeClass('hidden');
            $('#tab2addjob').trigger('click');
            valid = false;
        }
    }

    // English Attachments
    if (typeof $('#attachment-en')[0].files[0] !== "undefined") {
        if (!hasExtension($('#attachment-en')[0].files[0].name, ['txt', 'jpg', 'gif', 'png', 'jpeg', 'pdf', 'doc', 'docx'])) {
            $('.en-file-bad-extension').removeClass('hidden');
            $('#tab3addjob').trigger('click');
            valid = false;
        }
        if ($('#attachment-en')[0].files[0].size > 31457280) {
            $('.en-file-too-big').removeClass('hidden');
            $('#tab3addjob').trigger('click');
            valid = false;
        }
    }

    return valid;
}
function fallBackOnSelectedLangIfAny() {
    var fallBackOn = "nothing"
    if (frenchChecked) {
        fallBackOn = "french"
    }
    if (dutchChecked) {
        fallBackOn = "dutch"
    }
    if (englishChecked) {
        fallBackOn = "english"
    }

    //console.log(fallBackOn);
    switch (fallBackOn) {
        case "french":
            activateAndFocusOnFrench();
            break;
        case "dutch":
            activateAndFocusOnDutch();
            break;
        case "english":
            activateAndFocusOnEnglish();
            break;
        case "nothing":
            resetLangForm();
            break;
    }
}

/**
 * Event listener on Tab Button
 */
function jobTabInitialization() {
    resetLangForm();
    $('#tab1addjob').click(function(e) {
        e.preventDefault();
        $('#tab1addjob').removeClass('inactive').addClass('active');
        $('#tab2addjob').removeClass('active').addClass('inactive');
        $('#tab3addjob').removeClass('active').addClass('inactive');
        $('#onglet-1').show('slow');
        $('#onglet-2').hide('slow');
        $('#onglet-3').hide('slow');
    });
    $('#tab2addjob').click(function(e) {
        e.preventDefault();
        $('#tab2addjob').removeClass('inactive').addClass('active')
        $('#tab1addjob').removeClass('active').addClass('inactive');
        $('#tab3addjob').removeClass('active').addClass('inactive');
        $('#onglet-1').hide('slow');
        $('#onglet-2').show('slow');
        $('#onglet-3').hide('slow');
    });
    $('#tab3addjob').click(function(e) {
        e.preventDefault();
        $('#tab3addjob').removeClass('inactive').addClass('active')
        $('#tab1addjob').removeClass('active').addClass('inactive');
        $('#tab2addjob').removeClass('active').addClass('inactive');
        $('#onglet-1').hide('slow');
        $('#onglet-2').hide('slow');
        $('#onglet-3').show('slow');
    });

    // French
    $('#inlineCheckbox1').on('click', function(event) {
        var checkbox = document.getElementById("inlineCheckbox1");
        if (checkbox.checked == true)  {
            frenchChecked = true;
            activateAndFocusOnFrench();
        } else {
            frenchChecked = false;
            disableFrenchTab();
        }
    })

    // Dutch
    $('#inlineCheckbox2').on('click', function(event) {
        var checkbox = document.getElementById("inlineCheckbox2");
        if (checkbox.checked == true)  {
            dutchChecked = true;
            activateAndFocusOnDutch();
        } else {
            dutchChecked = false;
            disableDutchTab();
        }
    })

    // English
    $('#inlineCheckbox3').on('click', function(event) {
        var checkbox = document.getElementById("inlineCheckbox3");
        if (checkbox.checked == true)  {
            englishChecked = true;
            activateAndFocusOnEnglish();
        } else {
            englishChecked = false;
            disableEnglishTab();
        }
    })

    if ($("#page-data-state .description-fr").attr('data-is-active') == "1") {
        frenchChecked = true;
        activateAndFocusOnFrench();
    }

    if ($("#page-data-state .description-nl").attr('data-is-active') == "1") {
        dutchChecked = true;
        activateAndFocusOnDutch();
    }

    if ($("#page-data-state .description-en").attr('data-is-active') == "1") {
        englishChecked = true;
        activateAndFocusOnEnglish();
    }
}


/**
 * Reset FR NL EN Form
 */
function resetLangForm() {
    $('#onglet-1 input, #onglet-1 textarea').prop('disabled', true);
    $('#onglet-2 input, #onglet-2 textarea').prop('disabled', true);
    $('#onglet-3 input, #onglet-3 textarea').prop('disabled', true);
    $('#tab1addjob, #tab2addjob, #tab3addjob').prop('disabled', true);

    $('.button-ong').removeClass('inactive').addClass('active');
    $('#onglet-1').hide('slow');
    $('#onglet-2').hide('slow');
    $('#onglet-3').hide('slow');
}

/**
 *  Display and Hide French Tab
 */

// Display French Tab
function activateAndFocusOnFrench() {
    $('#onglet-1').removeAttr('disabled')
    $('#onglet-1 input, #onglet-1 textarea').removeAttr('disabled');
    $('#tab1addjob').removeAttr('disabled');

    $(this).removeClass('inactive').addClass('active');
    $('#tab2addjob').removeClass('active').addClass('inactive');
    $('#tab3addjob').removeClass('active').addClass('inactive');
    $('#onglet-1').show('slow');
    $('#onglet-2').hide('slow');
    $('#onglet-3').hide('slow');
    $('#hasFrenchDescription').val("true")
}

function disableFrenchTab() {
    $('#onglet-1 input, #onglet-1 textarea').prop('disabled', true);
    $('#tab1addjob').prop('disabled', true);
    $('#hasFrenchDescription').val("false")
    fallBackOnSelectedLangIfAny();
}

// Display Dutch Tab
function activateAndFocusOnDutch() {
    $('#onglet-2').removeAttr('disabled')
    $('#onglet-2 input, #onglet-2 textarea').removeAttr('disabled');
    $('#tab2addjob').removeAttr('disabled');
    $('#tab2addjob').removeClass('inactive').addClass('active')
    $('#tab1addjob').removeClass('active').addClass('inactive');
    $('#tab3addjob').removeClass('active').addClass('inactive');
    $('#onglet-1').hide('slow');
    $('#onglet-2').show('slow');
    $('#onglet-3').hide('slow');
    $('#hasDutchDescription').val("true")
}

function disableDutchTab() {
    $('#onglet-2 input, #onglet-2 textarea, #onglet-2 file').prop('disabled', true);
    $('#tab2addjob').prop('disabled', true);
    $('#hasDutchDescription').val("false")
    fallBackOnSelectedLangIfAny();
}

// Display English Tab
function activateAndFocusOnEnglish() {
    $('#onglet-3').removeAttr('disabled')
    $('#onglet-3 input, #onglet-3 textarea').removeAttr('disabled');
    $('#tab3addjob').removeAttr('disabled');

    $('#tab3addjob').removeClass('inactive').addClass('active')
    $('#tab1addjob').removeClass('active').addClass('inactive');
    $('#tab2addjob').removeClass('active').addClass('inactive');
    $('#onglet-1').hide('slow');
    $('#onglet-2').hide('slow');
    $('#onglet-3').show('slow');
    $('#hasEnglishDescription').val("true")
}

englishChecked = false;
function disableEnglishTab() {
    $('#onglet-3 input, #onglet-3 textarea').prop('disabled', true);
    $('#tab3addjob').prop('disabled', true);
    $('#hasEnglishDescription').val("false")
    fallBackOnSelectedLangIfAny();
}

/**
 * Select 2 Initialisation
 */

function initSelect2Config() {
    var select2BaseConfig = select2Config.getSelect2BaseConfig();

    var select2NameContactConfig = JSON.parse(JSON.stringify(select2BaseConfig));
        select2NameContactConfig.tags = true;

    $('#company').select2(select2BaseConfig);
    $('#job_type').select2(select2BaseConfig);
    $('#job_type').select2(select2BaseConfig);
    $('#name_contact').select2(select2NameContactConfig);
    $('#country').select2(select2BaseConfig);
}

/**
 * Belgium / Foreign state container
 */

function getItsBelgium() {
    return itsBelgium;
}

 function enableBelgiumContainer() {
    itsBelgium = true;
    $('#belgium-container').removeClass('hidden');
    $('#belgium-container').prop('disabled', false);
    $('#foreign-container').addClass('hidden');
    $('#foreign-container').prop('disabled', true);
    $('#region').prop('disabled', true);
    $('#foreign-city').prop('disabled', true);
    $('#cities').prop('disabled', false);
    $('#zip').prop('disabled', false);
    $('#province').prop('disabled', false);
    $('#region').prop('disabled', true);
}

function enableForeignContainer() {
    itsBelgium = false;
    $('#belgium-container').addClass('hidden');
    $('#belgium-container').prop('disabled', true);
    $('#foreign-container').removeClass('hidden');
    $('#foreign-container').prop('disabled', false);
    $('#region').prop('disabled', false);
    $('#foreign-city').prop('disabled', false);
    $('#cities').prop('disabled', true);
    $('#zip').prop('disabled', true);
    $('#province').prop('disabled', true);
    $('#region').prop('disabled', false);
}

function initBelgiumOrForeignCountryContainer() {
    // in Update mode, we need to display the right form if the user choosed between "belgium" or "foreign state"
    var checkedFormID = $('#belgiumOrForeignRadioGrp input:checked').attr('id');

    if (checkedFormID == 'belgiumRadio') {
        enableBelgiumContainer();
    }

    if (checkedFormID == 'strangerRadio') {
        enableForeignContainer();
    }

    $('#belgiumRadio, #strangerRadio').on('change', function() {
        var checkButtonVal = $('#belgiumOrForeignRadioGrp input:checked').val();
        if (checkButtonVal === 'belgium') {
            enableBelgiumContainer();
        }

        if (checkButtonVal === 'foreign_state') {
            enableForeignContainer();
        }
    })
}