import { relativeTimeRounding } from "moment";

export {init};


function init() {
    $('#password-container > .password-container input, #password-container > .password-confirm-container input').on('input', function() {
        $('#password-container > .password-doesnt-match').addClass('hidden');
    })

    $('#grpEmail input').on('input', function() {
        $('#grpEmail .error-msg').addClass('hidden');
    })

    $('#password').on('input', function() {
        if ($('#password').val() !== '')  {
            $('.password-confirm-container').removeClass('hidden');
            $('#password-confirm').prop('disabled', false);
        } else {
            $('.password-confirm-container').addClass('hidden');
            $('#password-confirm').prop('disabled', true);
        }
    })

    /**
     *  When Updating Manager
     */
    // Hide / Show Password
    $("#formEditUser .password-container a").on('click', function(event) {
        event.preventDefault();
        if($('.password-container input').attr("type") == "text"){
            $('.password-container input').attr('type', 'password');
            $('.password-container i').addClass( "fa-eye-slash" );
            $('.password-container i').removeClass( "fa-eye" );
        }else if($('.password-container input').attr("type") == "password"){
            $('.password-container input').attr('type', 'text');
            $('.password-container i').removeClass( "fa-eye-slash" );
            $('.password-container i').addClass( "fa-eye" );
        }
    });

    // Hide / Show Password Confirmation
    $("#formEditUser .password-confirm-container a").on('click', function(event) {
        event.preventDefault();
        if($('.password-confirm-container input').attr("type") == "text"){
            $('.password-confirm-container input').attr('type', 'password');
            $('.password-confirm-container i').addClass( "fa-eye-slash" );
            $('.password-confirm-container i').removeClass( "fa-eye" );
        }else if($('.password-confirm-container input').attr("type") == "password"){
            $('.password-confirm-container input').attr('type', 'text');
            $('.password-confirm-container i').removeClass( "fa-eye-slash" );
            $('.password-confirm-container i').addClass( "fa-eye" );
        }
    });


    $('#formEditUser').on('submit', function(event){
        event.preventDefault();

        if ($('#password').val() !== '') {
            if ($('#password').val() !== $('#password-confirm').val() ) {
                $('#password-container .password-doesnt-match').removeClass('hidden');
                return;
            }
        }

        var data = {};
        $('#formEditUser').serializeArray().map(function(item) {
            if ( data[item.name] ) {
                if ( typeof(data[item.name]) === "string" ) {
                    data[item.name] = [data[item.name]];
                }
                data[item.name].push(item.value);
            } else {
                data[item.name] = item.value;
            }
        });

        var userCompagniesSelected = [];
        $('#AddUserOnCompany_to > option').each(function() {  userCompagniesSelected.push($(this).val())})
        data.userCompagniesSelected = userCompagniesSelected;
        var url = window.location.pathname;
        $.ajax({
            method: "POST",
            url: url,
            data: data
          })
            .done(function( resp ) {
                var error = false;
                // console.log(resp);
                var data = resp; // JSON.parse(resp);
                if (data.error && data.error_code === 'USER_ALREADY_EXISTS') {
                    $('#grpEmail .user-already-stored').removeClass('hidden');
                    error = true;
                }
                if (data.error && data.error_code === 'SQL_ERROR') {
                    $('#grpEmail .unexpected-error-occured').removeClass('hidden');
                    error = true;
                }
                if (error) return;
                window.location.href = "/user/list";
            });
    });

    // Company Multiselect: When user is created, he can choice some companies using multi-select.js
    var translatedPlaceHolder = $('#multiselect-placeholder').attr('data-placeholder');
    $('#AddUserOnCompany').multiselect({
        search: {
            left: '<input type="text" name="q" class="form-control" placeholder="' + translatedPlaceHolder + '" />',
            right: '<input type="text" name="q" class="form-control" placeholder="' + translatedPlaceHolder + '" />',
        },
        fireSearch: function(value) {
            return value.length > 1;
        }
    });
}